import './FOOTER.css';
import FOOTER_EN from '../FOOTER_EN/FOOTER_EN';
import FOOTER_CH from '../FOOTER_CH/FOOTER_CH';


const FOOTER = (props) => {


	switch (props.nowLanguage) {

		case 'EN':
			return (<FOOTER_EN 
				changeLanguage = {props.changeLanguage}
			/>);
			break;

		case 'CH':
			return (<FOOTER_CH 
				changeLanguage = {props.changeLanguage}
			/>);
			break;

		default:
			return (<div></div>);
			break;
	}

	

}


export default FOOTER;