import React from 'react';
import MAIN_CONTENT_PROFILE from '../MAIN_CONTENT_PROFILE/MAIN_CONTENT_PROFILE';
import MAIN_CONTENT_PUBLICATIONS from '../MAIN_CONTENT_PUBLICATIONS/MAIN_CONTENT_PUBLICATIONS';
import MAIN_CONTENT_PROJECTS from '../MAIN_CONTENT_PROJECTS/MAIN_CONTENT_PROJECTS';
import MAIN_CONTENT_TEAM from '../MAIN_CONTENT_TEAM/MAIN_CONTENT_TEAM';
import MAIN_CONTENT_SERVICES from '../MAIN_CONTENT_SERVICES/MAIN_CONTENT_SERVICES';

import MAIN_CONTENT_INPUT_FORM from '../MAIN_CONTENT_INPUT_FORM/MAIN_CONTENT_INPUT_FORM';

const MAIN_CONTENT = (props) => {

	//console.log(props.profil_DB);

	switch (props.nowPage) {

		case 'Profile':
			return (
				<div id = 'main_scrollable_content' className = 'content'>
					<MAIN_CONTENT_PROFILE 
						nowLanguage = {props.nowLanguage}
						
						scrollIntroEN = {props.scrollIntroEN}
						scrollIntroCH = {props.scrollIntroCH}

						profil_DB = {props.profil_DB}

						data_finish_loading = {props.data_finish_loading}
					/>
				</div>
			);
			break;
			
		case 'Publications':
			return (
				<div id = 'main_scrollable_content' className = 'content'>
					<MAIN_CONTENT_PUBLICATIONS 
						nowLanguage = {props.nowLanguage}
						scrollPubliEN = {props.scrollPubliEN}
						scrollPubliCH = {props.scrollPubliCH}

						publi_DB = {props.publi_DB}
						publiENProfilPicUp = {props.publiENProfilPicUp}
          				publiCHProfilPicUp = {props.publiCHProfilPicUp}
          				publiENScrollTop = {props.publiENScrollTop}
						publiENScrollTopThres = {props.publiENScrollTopThres}
						publiCHScrollTop = {props.publiCHScrollTop}
						publiCHScrollTopThres = {props.publiCHScrollTopThres}

					/>
				</div>
			);
			break;

		case 'Projects':
			return (
				<div id = 'main_scrollable_content' className = 'content'>
					<MAIN_CONTENT_PROJECTS 
						nowLanguage = {props.nowLanguage}
						scrollPrjEN = {props.scrollPrjEN}
						scrollPrjCH = {props.scrollPrjCH}

						proj_DB = {props.proj_DB}
					/>
				</div>
			);
			break;

		case 'Team':
			return (
				<div id = 'main_scrollable_content' className = 'content'>
					<MAIN_CONTENT_TEAM 
						nowLanguage = {props.nowLanguage}
						scrollTemEN = {props.scrollTemEN}
						scrollTemCH = {props.scrollTemCH}

						team_DB = {props.team_DB}
					/>
				</div>
			);
			break;

		case 'Services':
			return (
				<div id = 'main_scrollable_content' className = 'content'>
					<MAIN_CONTENT_SERVICES 
						nowLanguage = {props.nowLanguage}
						scrollSerEN = {props.scrollSerEN}
						scrollSerCH = {props.scrollSerCH}

						ser_DB = {props.ser_DB}
					/>
				</div>
			);
			break;

		case 'InputForm':
			return (
				<div id = 'main_scrollable_content' className = 'content'>
					<MAIN_CONTENT_INPUT_FORM 
						profil_DB = {props.profil_DB}
						publi_DB = {props.publi_DB}
						proj_DB = {props.proj_DB}
						team_DB = {props.team_DB}
						ser_DB = {props.ser_DB}

						input_page = {props.input_page}
						input_page_selected = {props.input_page_selected}
						input_section = {props.input_section}
						input_section_selected = {props.input_section_selected}


						input_check_btn_handler = {props.input_check_btn_handler}
						show_input_check_text = {props.show_input_check_text}
						input_preview = {props.input_preview}

						input_submit = {props.input_submit}

						admin_authenticated = {props.admin_authenticated}
						authentication = {props.authentication}

					/>
				</div>
			);
			break;

		default:
			break;
	}
	
}


export default MAIN_CONTENT;
