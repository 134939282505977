import React from 'react';
import './HEADER.css';

import imgPro from '../img/tzubin.png';
import imgPub from '../img/publication.png';
import imgPrj from '../img/projects.png';
import imgTeam from '../img/team.png';
import imgSer from '../img/flower.png';


const HEADER = (props) => {


	let navEN = [];
	let navCH = [];
	let enNavNames = ['Profile', 'Publications', 'Projects', 'Team', 'Services'];
	let chNavNames = ['學經歷', '發表著作', '研究專案', '研究團隊', '社會服務'];
	let imgs = [imgPro, imgPub, imgPrj, imgTeam, imgSer];


	for (let i = 0 ; i < enNavNames.length ; i ++) {
		let nav = enNavNames[i];

		if (nav === props.nowPage) {
			navEN.push(
				<div className = 'navItem' onClick = {props.changePage} key = {nav} id = {nav}>
					<span className = 'nav-word-click'>{nav}</span>
					<br/>
					<img src = {imgs[i]} alt = {nav} />
				</div>
			);
			navCH.push(
				<div className = 'navItem' onClick = {props.changePage} key = {chNavNames[i]} id = {nav}>
					<span className = 'nav-word-click'>{chNavNames[i]}</span>
					<br/>
					<img src = {imgs[i]} alt = {chNavNames[i]} />
				</div>
			);
		}
		else {
			navEN.push(
				<div className = 'navItem' onClick = {props.changePage} key = {nav} id = {nav}>
					<span>{nav}</span>
					<br/>
					<img src = {imgs[i]} alt = {nav} />
				</div>
			);
			navCH.push(
				<div className = 'navItem' onClick = {props.changePage} key = {chNavNames[i]} id = {nav}>
					<span>{chNavNames[i]}</span>
					<br/>
					<img src = {imgs[i]} alt = {chNavNames[i]} />
				</div>
			);
		}
		
	}

	let navCH2 = [];
	let navEN2 = [];
	for (let i = 0 ; i < enNavNames.length ; i ++) {
		let nav2 = enNavNames[i];

		if (nav2 === props.nowPage) {
			navEN2.push(
				<div className = 'navItem' onClick = {props.changePage} key = {nav2} id = {nav2}>
					<span className = 'nav-word-click'>{nav2}</span>
					<br/>
				</div>
			);
			navCH2.push(
				<div className = 'navItem' onClick = {props.changePage} key = {chNavNames[i]} id = {nav2}>
					<span className = 'nav-word-click'>{chNavNames[i]}</span>
					<br/>
				</div>
			);
		}
		else {
			navEN2.push(
				<div className = 'navItem' onClick = {props.changePage} key = {nav2} id = {nav2}>
					<span>{nav2}</span>
					<br/>
				</div>
			);
			navCH2.push(
				<div className = 'navItem' onClick = {props.changePage} key = {chNavNames[i]} id = {nav2}>
					<span>{chNavNames[i]}</span>
					<br/>
				</div>
			);
		}
		
	}

	
	//console.log(navItems);

	let showFullNavbar = 
		props.introENScrollTop > props.introENScrollTopThres 
		&& props.introCHScrollTop > props.introCHScrollTopThres
		&& props.publiENScrollTop > props.publiENScrollTopThres
		&& props.publiCHScrollTop > props.publiCHScrollTopThres
		&& props.prjENScrollTop > props.prjENScrollTopThres
		&& props.prjCHScrollTop > props.prjCHScrollTopThres
		&& props.teamENScrollTop > props.teamENScrollTopThres
		&& props.teamCHScrollTop > props.teamCHScrollTopThres
		&& props.serENScrollTop > props.serENScrollTopThres
		&& props.serCHScrollTop > props.serCHScrollTopThres;
	 


	if (props.nowLanguage === 'EN') {
		return (
			<div id = 'headEN' className = 'head'>
		      <h1 className = "title eng" onClick = {props.to_input_page}>Dr. Tzu-Bin Lin's Website</h1>
		      <div className = "navbar">
		      	{showFullNavbar ?
		      		<div className = 'navitem'>{navEN}</div>
		      		: <div className = 'navitem'>{navEN2}</div>
		      	}   
		        <br />
		        <hr id="line" size="3px" / >
		      </div>
		    </div>
		);
	}

	else {
		return (
			<div id = 'headEN' className = 'head'>
		      <h1 className = "title eng" onClick = {props.to_input_page}>林子斌博士個人網站</h1>
		      <div className = "navbar">
		      	{showFullNavbar ?
		      		<div className = 'navitem'>{navCH}</div>
		      		: <div className = 'navitem'>{navCH2}</div>
		      	}   
		        <br />
		        <hr id="line" size="3px" / >
		      </div>
		    </div>
		);
	} 

	   	
	
	
}


export default HEADER;





{/*


<ul id = "pc-navbar">
	          <li className = "navitem navbar-click" id = "profile" onClick = {props.changePage}>
	          	<span className = "nav-word nav-word-click eng" name = "profile">Profile</span>
	          	<div className = "nav-profile" name = "profile"></div>
	          </li>
	          <li className = "navitem" id="publications"><span className = "nav-word eng" name="publications">Publications</span><div className="nav-publications" name="publications"></div></li>
	          <li className="navitem" id="projects"><span className="nav-word eng" name="projects">Projects</span><div className="nav-projects" name="projects"></div></li>
	          <li className="navitem" id='team'><span className="nav-word eng" name="team">Team</span><div className="nav-team" name="team"></div></li>
	          <li className="navitem" id="services"><span className="nav-word eng" name="services">Services</span><div className="nav-services" name="services"></div></li>
	        </ul>

*/}