import pdf_icon from '../img/pdf-icon.png';
import publi_TBL from '../img/publi_TBL.png';
import GS from '../img/google-scholar-with-link.png';
import ch_youtube from '../img/ch_youtube.png';
import ch_google_scholar from '../img/ch_google_scholar.png';

import './MAIN_CONTENT_PUBLICATIONS_CH.css';

const MAIN_CONTENT_PUBLICATIONS_CH = (props) => {

	let all_cat_id = Object.keys(props.publiCH_DB);
	//console.log(all_cat_id);
	let all_cat_title = [];
	let all_cat_content = [];
	let publiNavbar = [];

	for (let i = 0 ; i < all_cat_id.length ; i ++) {
		let ref_id = '#' + all_cat_id[i] + '-ch';
		publiNavbar.push(<li><a href = {ref_id}>{props.publiCH_DB[all_cat_id[i]][0]['快捷頁籤名稱']}</a></li>);


		if (props.publiCH_DB !== undefined) {
		  if (props.publiCH_DB[all_cat_id[i]].length > 0) {
		    let tmp = [];
		    let ch_id = all_cat_id[i] + '-ch';
		    all_cat_title.push(<h3 id = {ch_id}>{props.publiCH_DB[all_cat_id[i]][0]['標題名稱']}</h3>);
		  
		    //for (let j = 0 ; j < props.publiCH_DB[all_cat_id[i]].length ; j ++) {
		    for (let j = props.publiCH_DB[all_cat_id[i]].length - 1 ; j >= 0 ; j --) {
		      let cur = props.publiCH_DB[all_cat_id[i]][j];

		      let no_code = true;
		      let res_ref_main = [];
		      if (cur['程式碼格式'] !== undefined) {
		      	if (cur['程式碼格式'] !== '') {
		      		let ref_main = cur['程式碼格式'];
					let arr_ref_main = ref_main.split('#');
					//console.log(arr_ref_main);
					for (let idx = 0 ; idx < arr_ref_main.length ; idx ++) {
					//console.log(arr_ref_main[idx]);
						if (arr_ref_main[idx].length > 2) {
							if (arr_ref_main[idx].slice(0,2) === 'bf') {
								res_ref_main.push(<strong>{arr_ref_main[idx].slice(3)}</strong>);
							}
							else if (arr_ref_main[idx].slice(0,2) === 'sl') {
								res_ref_main.push(<i>{arr_ref_main[idx].slice(3)}</i>);
							}
							else res_ref_main.push(arr_ref_main[idx]);
						}
						else res_ref_main.push(arr_ref_main[idx]);
					}

					no_code = false;
		      	}
		      }
		      
		      if (no_code) {
		      	if (cur['第一段'] !== undefined) {
	              if (cur['第一段'] !== '') {
	                if (cur['第一段'].includes('林子斌')) {
	                  let tmp = cur['第一段'].split('林子斌');
	                  res_ref_main.push(tmp[0]);
	                  res_ref_main.push(<strong>林子斌</strong>);
	                  res_ref_main.push(tmp[1]);
	                }
	                else if (cur['第一段'].includes('Lin, T.-B.')) {
	                  let tmp = cur['第一段'].split('Lin, T.-B.');
	                  res_ref_main.push(tmp[0]);
	                  res_ref_main.push(<strong>Lin, T.-B.</strong>);
	                  res_ref_main.push(tmp[1]);
	                }
	                
	              }
	            }

	            if (cur['第二段'] !== undefined) {
	              if (cur['第二段'] !== '') {
	                if (cur['第二段字體'] === '斜體') {
	                  res_ref_main.push(<i>{cur['第二段']}</i>);
	                }
	                else if (cur['第二段字體'] === '粗體') {
	                  res_ref_main.push(<strong>{cur['第二段']}</strong>);
	                }
	              }
	            }

	            if (cur['第三段'] !== undefined) {
	              if (cur['第三段'] !== '') {
	                res_ref_main.push(cur['第三段']);
	              }
	            }

	            if (cur['第四段'] !== undefined) {
	            	if (cur['第四段'] !== '') {
	            		res_ref_main.push(<strong>{cur['第四段']}</strong>);
	            	}
	            }
		      }


		      if (cur['PDF連結'] !== undefined) {
		        if (cur['PDF連結'] !== '') {
		          tmp.push(<li>{res_ref_main}<a href = {cur['PDF連結']} target = '_blank'><img src = {pdf_icon} /></a></li>);
		        }
		        else {
		          tmp.push(<li>{res_ref_main}</li>);
		        }
		      }
		      else {
		        tmp.push(<li>{res_ref_main}</li>);
		      }
		    }
		    all_cat_content.push(tmp);
		  }
		}

	}

	let all_publi_content = [];
  
	for (let i = 0 ; i < all_cat_id.length ; i ++) {
		all_publi_content.push(
		  <div className = 'content-box'>
		    {all_cat_title[i]}<ol>{all_cat_content[i]}</ol>
		  </div>
		);
		all_cat_content.push(<br/>);
		all_cat_content.push(<br/>);
		all_cat_content.push(<br/>);
	}




	return (

		<div className="inner inner-publications" id = 'inner_publi_content_ch'>
				<div className="big column" id = 'publiCH' onScroll = {props.scrollPubliCH}>
					<div className="menu-bar"><ul>{publiNavbar}</ul></div>
					<br/>
					{all_publi_content}
				</div>
					{/*
					<div className="content-box">
						<div className="space"></div>
						<h3 id="journal-papers">中英文期刊論文</h3>
						<ol>{jp}</ol>
					</div>

					<br/><br/><br/>
					
					<div className="content-box">
						<div className="space"></div>
						<h3 id="books-related">學術專書</h3>
						<ol>{sb}</ol>
					</div>	

					<br/><br/><br/>

					<div className="content-box">
						<div className="space"></div>
						<h3 id="books-written">中英文學術專書章節</h3>
						<ol>{chap_in_sb}</ol>
					</div>			
					
					<br/><br/><br/>

					<div className="content-box">
						<div className="space"></div>
						<h3 id="conferences">中英文研討會論文</h3>
						<ol>{conferences}</ol>
					</div>
				*/}
				
				<div className="small column">
					<div className="profile-pic-2">
						<span className="profile-pic-circle"><a href="https://goo.gl/rxy6Ef" target="_blank"><img className="scroll-up" src= {publi_TBL} /></a></span>
						{props.publiCHScrollTop > props.publiCHScrollTopThres ?
							<div className = 'hyperlink-ch'>
								<span className="google-scholar-new-ch"><a href="https://goo.gl/rxy6Ef" target="_blank"><img className="google-scholar-up" src= {ch_google_scholar} /></a></span>
					            <span className = 'google-scholar-new-ch'><a href = "https://www.youtube.com/channel/UCPMwuKAtOWFdUSST5qs7DzQ" target = "_blank"><img src = {ch_youtube} /></a></span>
				         	</div>
				         	: <div className = 'hyperlink-ch forcedown-ch'>
								<span className="google-scholar-new-ch"><a href="https://goo.gl/rxy6Ef" target="_blank"><img className="google-scholar-up" src= {ch_google_scholar} /></a></span>
					            <span className = 'google-scholar-new-ch'><a href = "https://www.youtube.com/channel/UCPMwuKAtOWFdUSST5qs7DzQ" target = "_blank"><img src = {ch_youtube} /></a></span>
				         	</div>
						}
						
					</div>
					
				</div>
			</div>
		);


}


export default MAIN_CONTENT_PUBLICATIONS_CH;





{/*
	<li>Lin, T.-B., & Chen, P. (in press). The Inception of a Curriculum Leadership Development Program in Taiwan: Rationales and Designs. Chinese Education and Society. TBA. <b>(SCOPUS)</b></li>

	<li>Chen, D.-T., Lin, T.-B., Li, J.-Y., Lee, L. (2018). Establishing the Norm of New Media Literacy of Singaporean Students: Implications to Policy and Pedagogy. Computers & Education, 124, 1-13. (Corresponding author). (Corresponding author) <b>(SSCI)</b><a href="./files/JP/2018_CAE.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>江宜芷、林子斌、孫宇安 (2018)。理解媒體素養：以大學生的批判性消費素養認知為例。教育實踐與研究。教育實踐與研究。31(1)，1-38。(本人為通訊作者)<b> (TSSCI)</b><br/>
		[Chiang, Y.-C., Lin, T.-B., & Sun, Y.-A. (accepted). Unpacking Media Literacy: Exploring Undergraduate Students’ Understanding towards Critical Consuming. Journal of Educational Practice and Research, 31(1), 1-38. (Corresponding author) <b>(TSSCI)]</b><a href="./files/JP/03-17046林子斌等3人01-38.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	
	<li>林子斌 (2017)。見樹也見林的問題解決工具：系統思考在臺灣中等教育的可能性與挑戰。中等教育季刊，684。<br/>
		[Lin, T.-B.(2017). A Practical Tool for Problem-Solving: The Possibilities and Challenges of Promoting Systems Thinking in Taiwanese Secondary Education. Secondary Education, .]<a href="./files/JP/JP-3-林子斌（2017，12）。見樹也見林的問題解決工具系統思考在台灣中等教育的可能性與挑戰。中等教育季刊，68(4)，8-15.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>孫宇安、林子斌 (2017)。遊戲中學系統思考：以國中之課程領導增能課程為例。中等教育季刊，684。<br/>
		[Sun, Y.-A., & Lin, T.-B.(2017). Learning Systems Thinking through Board-Game: Case Study of a Cultivating Curriculum Leadership Program. Secondary Education, . ]<a href="./files/JP/JP-4-林子斌（2017，12）。遊戲中學系統思考以國中之課程領導增能課程為例。中等教育季刊，68(4)，36-53.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Hsieh, R. W., Chen, L. K., Chen, T-F., Liang, J.-C., Lin, T.-B., Chen, Y.-Y., Tsai, C.-C. (2016). The Association Between Internet Use and Ambulatory Care-Seeking Behaviors in Taiwan: A Cross-Sectional Study. Journal of Medical Internet Research, 18(12), 1-11. <b>(SCI)</b><a href="./files/JP/JP-5-2016_JMIR.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Lin, T.-B. (2016). Reigniting the passion for learning － A systematic attempt in Taipei. Frontiers of Education in China, 11(3), 356-373. DOI: 10.3868/s110-005-016-0028-0<b> (SCOPUS)</b><a href="./files/JP/JP-6-Reigniting the Passion for Learning- A Systematic Attempt in Taipei.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>陳佩英、林子斌 (2015)。以異業合作的行動研究發展校長專業增能課程。教育實踐與研究 (頁131-166)，28(1)。<b> (TSSCI)</b><br/>
		[Chen, P., & Lin, T.-B. (2015). The Development of Professional Empowerment Program for Principals by Interorganizational Collaboration and Action Research. Journal of Educational Practice and Research, 28(1), 131-166. <b>(TSSCI)</b> ]<a href="./files/JP/JP-7-2015_異業合作.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>林子斌、陳春男、何茂田 (2015)。學教翻轉：新北市提昇學習力的做法與挑戰。教育研究月刊 (頁17-32)，253。(第一暨通訊作者)<br/>
		[Lin, T.-B., Chen, C.-N., & Ho, M.-T. (2015). Teaching by Learning in an Inverted Classroom: The Means and Challenges of Raising Learning Power in New Taipei City. Journal of Education Studies, 253, 17-32. (First and corresponding author)]<a href="./files/JP/JP-8-2015_教育研究月刊.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Lee, L., Chen, V. D.-T., Li, J.-Y. & Lin, T.-B. (2015). Understanding New Media Literacy: The development of a measuring instrument. Computers & Education, 85, 84-93. DOI: 10.1016/j.compedu.2015.02.006. (Corresponding author) <b>(SSCI)</b><a href="./files/JP/JP-9-2015_CAE.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Lin, T.-B., Mokhtar, I., & Wang, L. (2015). The construct of media and information literacy in Singapore education system: global trends and local policies. Asia Pacific Journal of Education, 35(4), 423-437. (First and corresponding author) DOI:10.1080/02188791.2013.860012 <b>(SSCI)</b><a href="./files/JP/JP-10-APJE_SSCI.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Wang, L.-Y., & Lin, T.-B. (2014). Exploring the identity of pre-service NNESTs in Taiwan: A social relationally approach. English Teaching: Practice and Critique, 13(3), 5-29. <b>(SSCI) </b>(Corresponding author)<a href="./files/JP/JP-11-2014_ETPC.pdf" target="_blank"><img src="./images/pdf-icon.png" /></a></li>
	<li>Lin, T.-B., Wang, L.-Y., Li, J., & Chang, C. (2014). Pursuing Quality Education: The Lessons from the Education Reform in Taiwan. The Asia Pacific Education Researcher, 23(4), 813-822. <b>(SSCI)</b> (first and corresponding author) DOI: 10.1007/s40299-013-0135-4<a href="./files/JP/JP-12-2014TAPER.pdf" target="_blank"><img src = {pdf_icon} /></a></li>
	<li>Chen, P., & Lin, T.-B. (2014). Developing the Leadership Empowerment Program for School Principals in Taiwan. Leadership and Policy Quarterly, 3(2), 57-76.</li>
	<li>Wang, L., & Lin, T.-B. (2013). The Representation of Professionalism in Native English Speaking Teachers Recruitment Policies: A Comparative Study of Hong Kong, Japan, Korea and Taiwan. English Teaching: Practice and Critique, 12(3), 5-22. <b>(SSCI)</b> (second and corresponding author)<a href="./files/JP/JP-14-2013_ETPC.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Lin, T.-B., Li, J., Deng, F., & Lee, L. (2013). Understanding New Media Literacy: An Explorative Theoretical Framework. Journal of Educational Technology & Society, 16(4), 160-170. <b>(SSCI)</b> (first and corresponding author)<a href="./files/JP/JP-15-2013_ETS.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Hairon, S., Goh, J. W.-P., & Lin, T.-B. (2013). Distributed Leadership to Support PLCs in Asian Pragmatic Singapore Schools. International Journal of Leadership in Education: Theory and Practice, 17(3), 370-386. DOI:10.1080/13603124.2013.829586 <b>(SCOPUS)</b><a href="./files/JP/JP-16-2013-IJLE.pdf" target="_blank"><img src={pdf_icon} /></a></li>
	<li>Lin, T.-B., & Wu, C.-W. (2012). Teachers' Perceptions of Task-Based Language Teaching in English Classroom in Taiwanese Junior High Schools. TESOL Journal, 3(4), 586-609. (first and corresponding author)<a href="./files/JP/JP-17-2012_TBLT_published.pdf" target="_blank"><img src="./images/pdf-icon.png" /></a></li>
	<li>Zhang, Y., Lin, T.-B., Foo, S. F. (2012). Servant leadership: a preferred style of school leadership in Singapore. Chinese Management Studies, 6(2), 369-383. <b>(SSCI)</b><a href="./files/JP/JP-18-2012_Servant Leadership.pdf" target="_blank"><img src = {pdf_icon} /></a></li>
	*/}

{/*
	<li>Lin, T.-B., Chen, V. D.-T., & Chai, C.-S. (Eds.). (2015). New Media and Learning in the 21st Century: A socio-cultural perspective. Singapore: Springer. ISBN 978-981-287-325-5.</li>
	<li>潘惠玲 (主編)、王垠、江惠真、李文富、林子斌、林佳慧、范信賢、許孝誠、張淑惠、陳逸年、戴旭章與簡菲莉 (2016)。十二年國民基本教育普通高中課程規劃及行政準備手冊。新北市：國家教育研究院。</li>
	<li>Buckingham, D. (2006)。 媒體教育：素養、學習與當代文化(Media Education: Literacy, Learning and Contemporary Culture) (林子斌譯)。台北：巨流。(英文版出版於2003)。</li>
*/}


{/*
	<li>林子斌 (2017)。新加坡教育的發展探究 – C到A+的歷程。載於溫明麗 (主編)，亞洲教育的挑戰與展望 (頁111-136)。臺北市：國家教育研究院。</li>
	<li>Tan, K., Hairon, S., & Lin, T.-B. (2017). Grappling with Curriculum Leadership theory in schools. In K. Tan, Heng, M. A., & Lim-Ratnam, C. (Eds.) Curriculum Leadership by Middle Leaders: Theory, Design and Practice (pp. 10-25). London: Routledge.</li>
	<!--li>林子斌（2016）。引領國中課程的改變。帶起每個孩子：國民教育大進擊（95-102）。台北市：政大創造與創造力研究中心。</li-->
	<li>Lin, T.-B., & Wang, L. (2016). Native English-Speaking Teachers (NESTs) in Taiwan: policies and practices. In Fiona Copland, Sue Garton and Steve Mann (Eds.), LETs and NESTs: Voices, Views and Vignettes (pp. 151-168). UK: British Council.</li>
	<!--li>林子斌、邱淑娟（2015）。校長課程領導與增能課程規劃芻議。十二年國民基本教育全方位精進教學─增能與實踐（79-94）。台北：國立臺灣師範大學。</li-->
	<li>Lin, T.-B., Chen, V. D.-T., &. Chai, C.-S. (2015). Emerging Practices and Issues of New Media and Learning. In T.-B., Lin, V. D.-T., Chen, & C.-S., Chai (Eds.). New Media and Learning in the 21st Century: A socio-cultural perspective (pp. 1-8). Singapore: Springer.</li>
	<li>Lin, T.-B., & Choy, W. (2015). The Construction of Media in Education Policies: A Comparative Study of Singapore and Taiwan. In T.-B., Lin, V. D.-T., Chen, & C.-S., Chai (Eds.). New Media and Learning in the 21st Century: A socio-cultural perspective (pp. 93-108). Singapore: Springer.</li>
	<!--li>林子斌（2014）。了解新媒介素养：一个理论的框架。文章载于张开主编「媒介素养教育与包容性社会发展」（页86-90）。北京：中国传媒大学出版社。</li-->
	<li>Lin, T.-B., & Wang, L. (2013). The Construction of Information and Media Literacy in Education Policy: A Study of Singapore. In G. Walton, & M. Hepworth (Eds.), Developing People's Information Capabilities: Fostering Information Literacy in Educational, Workplace and Community Contexts (pp. 51-64). UK: Emerald.</li>
	<li>Liu, M., Lin, T.-B., & Tsai, J.-C. (2013). Chinese Taipei. In J. Ainley, W. Schulz, & T. Friedman (Eds.), ICCS 2009 Encyclopedia: Approaches to civic and citizenship education around the world (pp. 69-76). Amsterdam, the Netherlands: International Association for the Evaluation of Educational Achievement.</li>
	<li>Lin, T.-B., & Orsuwan, M. (2012). What is Media Literacy: A Study of Teachers’ Perceptions of Media Literacy Education in Taiwan. In C. K. Cheung (Ed.), Research in Media Education (pp. 45-62). New York: Nova Science Publishers, Inc.</li>

	*/}


{/*<!--div class="content-box">
	<div class="space"></div>
	<h3 id="talks">中英文主題演說與受邀講演</h3>
	<ol>
		<li>Lin, T.-B. (2016, SEP). Developing empowerment programs for school principal in Taiwan. Invited Talk, Seminar in the College of Education, Hong Kong University, Hong Kong.</li>
		<li>Lin, T.-B. (2015, SEP). Developing empowerment programs for school principal in Taiwan. Invited Talk, Seminar in the College of Education, Hong Kong University, Hong Kong.</li>
		<li>林子斌 (2015, AUG)。新加坡與英國之校長專業發展：對新北市校長的反思與啟示。新北市校長會議專題演講。淡水工商，新北市。</li>
		<li>林子斌 (2015, JAN)。 以學習者為中心的學校改變。松山高中專題演講，台北。</li>
		<li>Lin, T.-B. (2013, JUL). Qualitative methods and education research. Keynote Address, 2013 Postgraduate Summer Conference, Hong Kong, China. (HKIED)</li>
		<li>Lin, T.-B. (2012, SEP). Enhanced Practicum (EP) in Singapore: an introduction. Keynote Address, Conference on practicum and assessment in teacher education, Taipei, Taiwan.</li>
		<li>Lin, T.-B. (2012, AUG). Understanding ‘New Media’ Literacy: a theoretical framework. Keynote Address, the 3rd International Conference on Media Literacy, Lanzhou, China.</li>
		<li>Lin, T.-B. (2012, MAY). The Analysis of e-Learning policy: An explorative framework. Keynote Address, Annual Workshop on Science of Digital Learning, Hualien, Taiwan.</li>
		<li>Lin, T.-B. (2011, DEC). The preparation and professional development of school leaders in Singapore. Keynote Address, Conference on school principals’ training and evaluation programme in Asia Pacific region, Pingtung, Taiwan.</li>
		<li>Lin, T.-B. (2010, OCT). Professional Development Program for School Leaders in Singapore: LEP. Keynote Address, 2010 International Conference on Teacher In-service Education and Life-long Learning in Asia-Pacific Region, Pingtung, Taiwan.</li>
	</ol>
</div-->*/}





{/*
							<li>林子斌、黃家凱 (2017)。推廣課程領導的實踐：以學校課程領導人培育方案為例。林新發 (主持人)，領導模式與實踐。2017東亞地區校長學學術研討會，國立臺北教育大學。</li>
							<li>林子斌 (2017年11月)。影像教育，有「影」嘸？從研究發現談起。吳珮慈 (主持人)，國家電影中心研究案成果發表。2017臺法影像教育論壇，台北市北師美術館。</li>
							<li>蘇菀陵、王慕羽、林子斌 (2017年11月)。語言混搭－外籍英語教師聘僱政策的圖像勾勒：以新北市一所高中為例。發表於2017年11月12日中華民國英語文教師學會所舉辦之「第二十六屆中華民國英語文教學國際研討會暨書展」。臺北市：劍潭海外青年活動中心。</li>
							<li>Hung, Y.-T., Wang, M.-Y., and Lin, T-B. (2017, October). Mapping Out the Landscape of Education Leadership in Taiwan: A Systematic Review of Research 1995 To 2016. The 8th Asian Conference on Education (ACE), Kobe, Japan.</li>
							<li>Lin, T.-B. (2017, October). Continuing Professional Development for Educational Professionals in Secondary Schools: A Case Study of a Leadership Empowerment Program in Taiwan. Paper presented at the Asian Conference on Education (ACE), Japan.</li>
							<li>Lin, T.-B. (2017, March).The Collaboration Between Nests in Taiwanese Contexts: A Case Study of a Junior High School. Paper presented at the Asian Conference on Language Learning (ACLL), Japan.</li>
							<li>Chiang, Y., & Lin, T.-B. (2016, June). Believe It or Not? Embedded Health Literacy in Media Literacy Education: A Study of Taiwanese Undergraduate Students. Paper presented at The 7th Asian Conference in Social Science (ACSS), Kobe, Japan. 本人為通訊作者.</li>
							<li>Lin, T.-B. (2016). Exploring 21st Century Competence and Teacher Education in Taiwan: A Case of Media Literacy. The 11th International Symposium on Teacher Education in East Asia, CCNU. 本人為第一作者.</li>
							<li>Lin, T.-B. (2016, June). Recruiting Native English Speaking Teachers: a Case Study of Policy and Practices in New Taipei City. Paper presented at The 7th Asian Conference in Social Science (ACSS), Kobe, Japan.</li>
							<li>Wang, M.-Y., Chen, Y.-W., and Lin, T-B. (2016, October). Improving School Performance through Leadership Empowerment: A Case Study of National Leader of Education. In Lin, T.-B. (Chair), Interdisciplinary Approaches to Understand Education Issues in Taiwanese Context – Equity Policy, Leadership Development and Empowerment and Research Trend. Symposium conducted at the meeting of The 8th Asian Conference on Education(ACE), Kobe, Japan. 本人為通訊作者.</li>
							<li>江宜芷、林子斌 (2016年12月)。「再現」媒體素養：探討臺灣大學聯盟學生對媒體素養之理解。TAECT 2016 臺灣教育傳播暨科技學會2016年國際研討會。臺北市：臺灣教育傳播暨科技學會。</li>
							<li>Lin, T.-B., Chen, Y.-Y., Liang, J.-C., & Tsai, C.-C. (2016). Developing on Interdisciplinary Approach to Education Research: A Case Study of Media Literacy in Higher Education. Paper presented at the 8th Asian Conference on Education (ACE), Kobe, Japan.</li>
							<li>Huang, C.-K., &Lin, T.-B. (2016). Equity and Minority in Education: A Study of Indigenous Education Policy in Taiwan. Paper presented at the 8th Asian Conference on Education (ACE), Kobe, Japan.</li>
							<li>Lin, T.-B. (2016). Exploring 21st Century Competence and Teacher Education in Taiwan: A Case of Media Literacy. The 11th International Symposium on Teacher Education in East Asia, Wuhan: Central China Normal University.</li>
							<li>林子斌 (2016年6月)。豐富學生的實習經驗：以實習輔導認證教師與駐區輔導計畫為例。發表於2016年6月17日國立臺灣師範大學師資培育與就業輔導處所舉辦之「標準本位、閃耀之星：精緻師資素質計畫Ｘ成果發表會」研討會。臺北市：國立臺灣師範大學。</li>
							<li>林子斌 (2016年6月)。提升教檢通過率之統整學習計畫。發表於2016年6月17日國立臺灣師範大學師資培育與就業輔導處所舉辦之「U-F/G/S-S夥伴協作學教有成：精進師資素質計畫Ｘ成果發表會」研討會。臺北市：國立臺灣師範大學。</li>
							<li>Lin, T.-B. (2016, June). Recruiting Native English Speaking Teachers: a Case Study of Policy and Practices in New Taipei City. Paper presented at The 7th Asian Conference in Social Science (ACSS), Kobe, Japan.</li>
							<li>Chiang, Y., & Lin, T.-B. (2016, June). Believe It or Not? Embedded Health Literacy in Media Literacy Education: A Study of Taiwanese Undergraduate Students. Paper presented at The 7th Asian Conference in Social Science (ACSS), Kobe, Japan.</li>
							<li>黃歆絜、林子斌 (2015年11月)。學校轉變的起點─一所國中教師專業學習社群的歷程與影響。發表於2015年11月20日國立臺灣師範大學教育系所舉辦之「教育的想像─演化與創新國際學術研討會」。臺北市：國立臺灣師範大學。</li>
							<li>孫宇安、林子斌 (2015年11月)。滾動修正、社群支持與回流持續的專業發展模式：以「學校課程領導人培育」方案為例。發表於2015年11月20日國立臺灣師範大學所舉辦之「教育的想像─演化與創新國際學術研討會」。臺北市：國立臺灣師範大學。</li>
							<li>Lin, T.-B., Liang, J-C, Chen, Y-Y, & Tsai, C-C. (2015, Nov). The Encounter between Media Literacy and Health Literacy: A Case Study of Interdisciplinary Curriculum Development in National Taiwan University Triangle. Paper presented at The 4th International Conference on Media Literacy, Hong Kong.</li>
							<li>Liu, M-H., & Lin, T.-B. (2015, Nov). The Quality Assurance for Student Teacher in Practicum. Paper presented at The 10th East Asia International Symposium on Teacher Education, Nagoya, Japan.</li>
							<li>Lin, T.-B., & Chen, P. (2015, Jun). Cultivating Curriculum Leaders:  Exploring the Development of Leadership Preparation Projects in Taipei. Paper presented at The Redesigning Pedagogy Conference, CRPP National Institute of Education, Singapore.</li>
							<li>Wu, C.-W., & Lin, T.-B. (2015, May). The Application of Social Media in an English Talent Programme: A Study of Longmen Junior High School. Paper presented at GCCCE 2015, Taipei, Taiwan.</li>
							<li>Lin, T.-B. (2015, March). Making School Change Happened Through Servant Leadership: An Ethnographic Study of a Junior High School Principal in Taipei. Paper presented at The Asian Conference on Education and International Development (ACEID), Osaka, Japan.</li>
							<li>林雅婷、李宜樺、藍偉瑩、林子斌、陳佩英、卯靜儒 (2014年11月)。教育改革中構築的主體、意義與關係﹣臺北市跨校教師共同備課經驗。發表於2014年11月15日國立臺灣師範大學所舉辦之「教改20年回顧與前瞻國際學術研討會」。臺北市：國立臺灣師範大學。</li>
							<li>Lin, T.-B. (2014, November). The Engcredible Path: exploring journey of a PLC in Taipei. Paper presented at The Asia Pacific Educational Research Association＆The Hong Kong Educational Research Association International Conference 2014, Hong Kong Institute of Education, Hong Kong.</li>
							<li>侯仲宸、林子斌（2014年11月）。教師領導與教師專業發展之研究﹣以臺北市一個跨校專業學習社群為例。Paper presented at The Asia Pacific Educational Research Association＆The Hong Kong Educational Research Association International Conference 2014, Hong Kong Institute of Education, Hong Kong.</li>
							<li>黃彥博、林子斌（2014年11月）。教師專業學習社群與校長領導相關研究﹣以臺北市中學為例。Paper presented at The Asia Pacific Educational Research Association＆The Hong Kong Educational Research Association International Conference 2014, Hong Kong Institute of Education, Hong Kong.</li>
							<li>Lee, L., Li, J.-Y., Lin T.-B., & Chen, V. D.-T. (2014, October/November). Where Our Youth Are in the New Media World: Measures of New Media Literacy. The Asian Conference on Education 2014 Official Conference Proceedings. Osaka, Japan.</li>
							<li>林子斌 (2014年5月)。「新」在何处？谈「新媒介素养教育」在台湾与新加坡的发展现况。論文發表於2014年5月11、12日浙江傳媒大學所舉辦之「第五屆西湖媒介素養高峰論壇」。浙江杭州：浙江傳媒大學。</li>
							<li>林子斌（2014）。縣市提升學習力的作法與挑戰：以新北市為例。載於國立臺灣師範大學教育研究與評鑑中心編「縣市教育力與教育發展」手冊（頁203）。</li>
							<li>Lin, T.-B., & Chen, P. (2014, January). In search of solution to improve school effectiveness: a case study of leadership preparation program in Taipei. In ICSEI 2014 (Ed.) The International Congress for School Effectiveness and Improvement Annual Conference 2014 (CD-ROM). Yogyakarta, Indonesia: ICSEI & Yogyakarta State University. （NSC 103-2914-I-003 -002 -A1）</li>
							<li>Lin, T.-B., & Chen, P. (2013, November). Leading to Teach: A Study of Leadership Preparation Program in Taipei. Paper presented at the International Conference on Change from within: Reinventing agency in teaching and learning, National Taiwan Normal University, Taiwan.</li>
							<li>陳佩英、林子斌 (2013)。學校領導越界學習之專業增能行動研究。載於國立台北教育大學編「兩岸三地校長學學術研討會」手冊 (頁233-252)。</li>
							<li>Lin, T.-B., & Deng, F. (2012). The construction of media literacy in education reform policies: a comparative study of policy discourses in Singapore and Taiwan. In (Ed.) The 13th International Conference on Education Research. Seoul, Korea: Seoul National University.</li>
							<li>Hairon, S., Goh, J.W.P, & Lin, T.-B. (2012, November). The necessity for distributed leadership in PLCs: The case for Singapore. Paper presented at the International Conference on Education Vision 2020, National Taiwan Normal University, Taiwan.</li>
							<li>Chua, S. K. C., & Lin, T.-B. (2011, July). "Join us: Be a teacher": A comparison of Singapore and Taiwanese teacher recruitment advertisements. Paper presented at 12th International Pragmatics Conference, Manchester, United Kingdom.</li>
							<li>Lin, T.-B. (2011, May). Professional development programme in higher education: A case study in England. Paper presented at the 4th Redesigning Pedagogy: Transforming Teaching, Inspiring Learning International Conference, Singapore.</li>
							<li>Lin, T.-B. (2011, May). Teacher's quality in the 21st century: a case study of Singapore. Paper presented at the international conference on the change of society and the role of teachers, Taitung, Taiwan.</li>
							<li>Lin, T.-B. (2011, June). Asian perspectives of the professional doctorate in education faculty: a pilot study in Singapore and Taiwan. Paper presented at Colloquium/Seminar: Quodlibetal Questions on Education, London, United Kingdom.</li>
							<li>Lin, T.-B., & Chua, S. K. C. (2011, December). Are we talking about the same thing? Media literacy in education policy discourse in Taiwan and Singapore. Paper presented at 2011 TERA International Conference on Education, Kaohsiung, Taiwan.</li>
							<li>Lin, T.-B., & Chua, S. K. C. (2011, May). Exploring the meaning of ICT leadership and its implication to school leaders in Singapore. Paper presented at The 4th Redesigning Pedagogy: Transforming Teaching, Inspiring Learning International Conference, Singapore.</li>
							<li>Lin, T.-B. (2010a). Localization of western discourse in Asian context: a case study of media literacy education. In Haldane, J. (Ed.) The Asian Conference on the Social Sciences (pp. 747-756). Osaka, Japan: The International Academic Forum, Japan.</li>
							<li>Lin, T.-B. (2010b). Media culture, education and social equality: a preliminary study of media education in Three Chinese-speaking regions. In Chiang, T.-H. (Ed.) The 2010 Forum on Education Functions and Equity in Mainland China and Taiwan (pp. 182-195). Tainan, Taiwan: National University of Tainan.</li>
							<li>Kim, A., & Lin, T.-B. (2010, November). The construction of new media in the discourse of media literacy: a comparative study of Korea and Taiwan. Paper presented at Media Literacy Conference, London, United Kingdom.</li>
							<li>Lin, T.-B. (2010, December). The Vision of Teacher Education in 21st-century Singapore: a study of ‘TE 21’. Paper presented at Asian Conference on Education 2010, Osaka, Japan.</li>
							<li>Lin, T.-B. (2010, November). Defining teacher competencies in the 21st century. Paper presented at The International Conference on the Key Competencies and Educational Innovation in a Global Era, Taipei, Taiwan.</li>
							<li>Lin, T.-B. (2010, June). A case study of Media Literacy Education. Paper presented at International Communication Association Conference: Media Literacy Education in Asia - New Development Panel, Singapore.</li>
							<li>Lin, T.-B., Wu, C. (2010). Taiwanese Junior High School Teachers' Perceptions of TBLT. In Haldane, J. (Ed.) The Asian Conference on the Social Sciences (pp. 1688-1699). Osaka, Japan: The International Academic Forum.</li>
							<li>Lin, T.-B., Wu, C. (2009). Junior high school teachers' understanding of TBLT in Taiwanese context. In English Teachers' Association (R.O.C.) (Ed.) Selected Papers from the Eighteenth International Symposium on English Teaching (pp. 420-430). Taipei, Taiwan: Crane Publishing Co. Ltd. & ETA-ROC.</li>
							<li>Lin, T.-B. (2009, November). Teaching about media: a borrowed solution in education for worsening media environment in Taiwan. Paper presented at The Asian conference on Education: local problem, global solution, Osaka, Japan.</li>
							<li>Lin, T.-B., & Orsuwan, M. (2009, November). Teaching media literacy: a study of teachers' perception of media education policy in Taiwan. Paper presented at The Eras conference, Singapore.</li>
							<li>Lin, T.-B. (2006). Media, consumerism, children and education policy: a case study of Taiwan's media education policy. In Copenhagen Business School (Ed.) The 2nd international conference on pluridisciplinary perspectives on child and teen consumption. (pp. 157-166). Copenhagen, Denmark: Copenhagen Business School.</li>
							<li>Lin, T.-B. (2006, September). Education policy making in Taiwan: a case study of the White Paper on Media Literacy Education. Paper presented at The BERA (British Education Research Association) Annual conference 2006, Warwick, United Kingdom.</li>
							<li>Lin, T.-B. (2006). Discourse of media literacy curriculum: a comparative study of the UK, the US and Taiwan. In Taiwan Association for Curriculum and Instruction (Ed.) Selected papers from the 12th annual conference of Taiwan Association for Curriculum and Instruction (pp. 268-284). Taipei, Taiwan: Taiwan Association for Curriculum and Instruction.</li>
							<li>Lin, T.-B. (2006). Critical discourse analysis and the research of education policy. In Department of Education, National Kaohsiung Normal University (Ed.) Papers on Education Research and Discourse (pp. 42-69). Kaohsiung, Taiwan: Department of Education, National Kaohsiung Normal University.</li>
							<li>Lin, T.-B., Chuang, S. (2006). Adopting discourse analysis in education research - micro or macro?. In College of Education, Tamkang University & Taiwan Association for Sociology of Education (Eds.) Papers from the 12th annual conference of Taiwan Association for Sociology of Education (pp. 416-436). Taipei, Taiwan: College of Education, Tamkang University & Taiwan Association for Sociology of Education.</li>
							<li>林子斌(2005)。論述分析在教育研究上的運用 – 以Stephen Ball的教育政策分析為例。論文發表於2005年6月17-18日「教育政策科學學術研討會」。台北︰國立臺灣師範大學教育研究中心。</li>
							<li>Lin, T.-B. (2005, September). Minority discourses as a means of resistance - a proposal for Taiwan's Indigenous education. Paper presented at The 4th Discourse, Power and Resistance conference, Plymouth, United Kingdom.</li>
							<li>Lin, T.-B. (2005, September). Media and racial issue: a new challenge for multicultural Taiwan. Paper presented at The 7th European Summer School conference, Crete, Greece.</li>
							<li>林子斌 (2004)。原住民教育中媒體素養議題初探。論文發表於2004年10月15、16日花蓮師範學院原住民教育中心所舉辦之「93年度原住民教育學術研討會」。花蓮：國立花蓮師範學院。</li>
							<li>林子斌 (2004)。媒體素養 – 臺灣多元文化教育之新課題。論文發表於2004年10月8至10日中國傳媒大學（Communication University of China）國際傳播學系所舉辦之「中國第一屆媒體素養教育國際學術研討會」。北京：中國傳媒大學。</li>
							<div className="space"></div>
							<div className="space"></div>
							<div className="space"></div>
							<div className="space"></div>
							<br/>

							*/}



{/*




let jp = [];
  let sb = [];
  let chap_in_sb = [];
  let conferences = [];


  if (props.publiEN_DB !== undefined) {

    if (props.publiEN_DB.jp.length > 0) {
      for (let i = 0 ; i < props.publiEN_DB.jp.length ; i ++) {
        let cur = props.publiEN_DB.jp[i];
        if (cur.file_src !== undefined) {
          if (cur.file_src !== '') {
            jp.push(<li>{cur.ref_main}<a href = {cur.file_src} target = '_blank'><img src = {pdf_icon} /></a></li>);
          }
          else {
            jp.push(<li>{cur.ref_main}</li>);
          }
        }
        else {
          jp.push(<li>{cur.ref_main}</li>);
        }
      }
    }


    if (props.publiEN_DB.sb.length > 0) {
      for (let i = 0 ; i < props.publiEN_DB.sb.length ; i ++) {
        sb.push(<li>{props.publiEN_DB.sb[i].ref_main}</li>);
      }
    }


    if (props.publiEN_DB.chap_in_sb.length > 0) {
      for (let i = 0 ; i < props.publiEN_DB.chap_in_sb.length ; i ++) {
        chap_in_sb.push(<li>{props.publiEN_DB.chap_in_sb[i].ref_main}</li>);
      }
    }

    if (props.publiEN_DB.conferences.length > 0) {
      for (let i = 0 ; i < props.publiEN_DB.conferences.length ; i ++) {
        conferences.push(<li>{props.publiEN_DB.conferences[i].ref_main}</li>);
      }
    }
  }

  let all_publi_content = [];
  
  for (let i = 0 ; i < all_cat_id.length ; i ++) {
    all_publi_content.push(
      <div className = 'content-box'>
        {all_cat_title[i]}<ol>{all_cat_content[i]}</ol>
      </div>
    );
    all_cat_content.push(<br/>);
    all_cat_content.push(<br/>);
    all_cat_content.push(<br/>);
  }
	let jp = [];
	let sb = [];
	let chap_in_sb = [];
	let conferences = [];

	if (props.publiCH_DB !== undefined) {

	    if (props.publiCH_DB.jp.length > 0) {
	      for (let i = 0 ; i < props.publiCH_DB.jp.length ; i ++) {
	        let cur = props.publiCH_DB.jp[i];
	        if (cur.file_src !== undefined) {
	          if (cur.file_src !== '') {
	            jp.push(<li>{cur.ref_main}<a href = {cur.file_src} target = '_blank'><img src = {pdf_icon} /></a></li>);
	          }
	          else {
	            jp.push(<li>{cur.ref_main}</li>);
	          }
	        }
	        else {
	          jp.push(<li>{cur.ref_main}</li>);
	        }
	      }
	    }


	    if (props.publiCH_DB.sb.length > 0) {
	      for (let i = 0 ; i < props.publiCH_DB.sb.length ; i ++) {
	        sb.push(<li>{props.publiCH_DB.sb[i].ref_main}</li>);
	      }
	    }


	    if (props.publiCH_DB.chap_in_sb.length > 0) {
	      for (let i = 0 ; i < props.publiCH_DB.chap_in_sb.length ; i ++) {
	        chap_in_sb.push(<li>{props.publiCH_DB.chap_in_sb[i].ref_main}</li>);
	      }
	    }

	    if (props.publiCH_DB.conferences.length > 0) {
	      for (let i = 0 ; i < props.publiCH_DB.conferences.length ; i ++) {
	        conferences.push(<li>{props.publiCH_DB.conferences[i].ref_main}</li>);
	      }
	    }
	  }*/}