import icon_email_red from '../img/email-red.png';
import icon_phone_red from '../img/phone-red.png';


const FOOTER_EN = (props) => {

	return (

		<div className="footer">
			<div className = 'footerTitle'>Tzu-Bin Lin / National Taiwan Normal University</div>
			<div className = 'footerIcon'>	
			<a href="mailto: tzubin_lin@ntnu.edu.tw, odin.london@gmail.com"> 
				<img className="footer-icon" src={icon_email_red}/>
			</a>

			<a href="tel:+886-02-7734-3893">
				<img className="footer-icon" src={icon_phone_red} />
			</a>
			</div>
	      
	      <div className = 'switch' onClick = {props.changeLanguage}>
	      	<button id = 'btnCH'>中文</button>
	      	<button id = 'btnEN'>English</button>

	      </div>
	      {/*<div >&nbsp;&nbsp;中文&nbsp;&nbsp;<a id="active-tag" href="./index.html">English</a></div>*/}
	    </div>
	);

}

export default FOOTER_EN;