import './MAIN_CONTENT_INPUT_FORM.css';

const MAIN_CONTENT_INPUT_FORM = (props) => {


	let section_selector_db = {};

	// profile
	let profilEN_DB = props.profil_DB[0];
	let profilCH_DB = props.profil_DB[1];
	let preselected_profil_tags = [<option value = ''>未選擇</option>];
	for (let item in profilEN_DB) {
		let key_id = '英文' + ' ' + profilEN_DB[item][0]['標題名稱'] + ' ' + profilEN_DB[item][0]['系統類別'];
		preselected_profil_tags.push(<option value = {key_id}>英文/ {profilEN_DB[item][0]['標題名稱']}</option>);
	}
	for (let item in profilCH_DB) {
		let key_id = '中文' + ' ' + profilCH_DB[item][0]['標題名稱'] + ' ' + profilCH_DB[item][0]['系統類別'];
		preselected_profil_tags.push(<option value = {key_id}>中文/ {profilCH_DB[item][0]['標題名稱']}</option>);
	}
	section_selector_db['Profile'] = [
		<tr><td>系統語言/ 標題名稱</td></tr>,
		<tr><td><select onChange = {props.input_section_selected} id = 'input_section_selector_profile'>{preselected_profil_tags}</select></td></tr>
	];

	// publications
	let publiEN_DB = props.publi_DB[0];
	let publiCH_DB = props.publi_DB[1];
	let preselected_publi_tags = [<option value = ''>未選擇</option>];
	for (let item in publiEN_DB) {
		let key_id = '英文' + ' ' + publiEN_DB[item][0]['標題名稱'] + ' ' + publiEN_DB[item][0]['系統類別'];
		preselected_publi_tags.push(<option value = {key_id}>英文/ {publiEN_DB[item][0]['標題名稱']}</option>);
	}
	for (let item in publiCH_DB) {
		let key_id = '中文' + ' ' + publiCH_DB[item][0]['標題名稱'] + ' ' + publiCH_DB[item][0]['系統類別'];
		preselected_publi_tags.push(<option value = {key_id}>中文/ {publiCH_DB[item][0]['標題名稱']}</option>);
	}
	section_selector_db['Publications'] = [
		<tr><td>系統語言/ 標題名稱</td></tr>,
		<tr><td><select onChange = {props.input_section_selected} id = 'input_section_selector_publications'>{preselected_publi_tags}</select></td></tr>
	];

	// Projects
	let projEN_DB = props.proj_DB[0];
	let projCH_DB = props.proj_DB[1];
	let preselected_proj_tags = [<option value = ''>未選擇</option>];
	for (let item in projEN_DB) {
		let key_id = '英文' + ' ' + projEN_DB[item][0]['標題名稱'] + ' ' + projEN_DB[item][0]['系統類別'];
		preselected_proj_tags.push(<option value = {key_id}>英文/ {projEN_DB[item][0]['標題名稱']}</option>);
	}
	for (let item in projCH_DB) {
		let key_id = '中文' + ' ' + projCH_DB[item][0]['標題名稱'] + ' ' + projCH_DB[item][0]['系統類別'];
		preselected_proj_tags.push(<option value = {key_id}>中文/ {projCH_DB[item][0]['標題名稱']}</option>);
	}
	section_selector_db['Projects'] = [
		<tr><td>系統語言/ 標題名稱</td></tr>,
		<tr><td><select onChange = {props.input_section_selected} id = 'input_section_selector_projects'>{preselected_proj_tags}</select></td></tr>
	];

	// Team
	let teamEN_DB = props.team_DB[0];
	let teamCH_DB = props.team_DB[1];
	let preselected_team_tags = [<option value = ''>未選擇</option>];
	for (let item in teamEN_DB) {
		let key_id = '英文' + ' ' + teamEN_DB[item][0]['標題名稱'] + ' ' + teamEN_DB[item][0]['系統類別'];
		preselected_team_tags.push(<option value = {key_id}>英文/ {teamEN_DB[item][0]['標題名稱']}</option>);
	}
	for (let item in teamCH_DB) {
		let key_id = '中文' + ' ' + teamCH_DB[item][0]['標題名稱'] + ' ' + teamCH_DB[item][0]['系統類別'];
		preselected_team_tags.push(<option value = {key_id}>中文/ {teamCH_DB[item][0]['標題名稱']}</option>);
	}
	section_selector_db['Team'] = [
		<tr><td>系統語言/ 標題名稱</td></tr>,
		<tr><td><select onChange = {props.input_section_selected} id = 'input_section_selector_team'>{preselected_team_tags}</select></td></tr>
	];

	// Services
	let serEN_DB = props.ser_DB[0];
	let serCH_DB = props.ser_DB[1];
	let preselected_services_tags = [<option value = ''>未選擇</option>];
	for (let item in serEN_DB) {
		let key_id = '英文' + ' ' + serEN_DB[item][0]['標題名稱'] + ' ' + serEN_DB[item][0]['系統類別'];
		preselected_services_tags.push(<option value = {key_id}>英文/ {serEN_DB[item][0]['標題名稱']}</option>);
	}
	for (let item in serCH_DB) {
		let key_id = '中文' + ' ' + serCH_DB[item][0]['標題名稱'] + ' ' + serCH_DB[item][0]['系統類別'];
		preselected_services_tags.push(<option value = {key_id}>中文/ {serCH_DB[item][0]['標題名稱']}</option>);
	}
	section_selector_db['Services'] = [
		<tr><td>系統語言/ 標題名稱</td></tr>,
		<tr><td><select onChange = {props.input_section_selected} id = 'input_section_selector_services'>{preselected_services_tags}</select></td></tr>
	];


	let section_selector = [];
	if (props.input_page !== '') section_selector = section_selector_db[props.input_page];


	//////////////////////




	


	////////////////
	let detail_input_table_profil = {};
	detail_input_table_profil['intro'] = [
		<tr>
			<td>新增段落</td>
			<td><input type = 'text' id = 'profil_input_p1' /></td>
			<td>備註</td>
			<td><input type = 'text' id = 'profil_input_memo' /></td>
		</tr>,
		<tr>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
	];
	detail_input_table_profil['edu'] = [
		<tr>
			<td>學位</td>
			<td><input type = 'text' id = 'profil_input_p1' /></td>
			<td>說明</td>
			<td><input type = 'text' id = 'profil_input_p2' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'profil_input_memo' /></td>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
		</tr>
	];
	detail_input_table_profil['work_exp'] = [
		<tr>
			<td>單位</td>
			<td><input type = 'text' id = 'profil_input_p1' /></td>
			<td>時間</td>
			<td><input type = 'text' id = 'profil_input_p2' /></td>
			<td>職銜</td>
			<td><input type = 'text' id = 'profil_input_p3' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'profil_input_memo' /></td>

			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
	];

	detail_input_table_profil['awards_and_grants'] = [
		<tr>
			<td>年份</td>
			<td><input type = 'text' id = 'profil_input_p1' /></td>
			<td>內容</td>
			<td><input type = 'text' id = 'profil_input_p2' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'profil_input_memo' /></td>

			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
		</tr>

	];

	detail_input_table_profil['research_interests'] = [
		<tr>
			<td>學術研究領域</td>
			<td><input type = 'text' id = 'profil_input_p1' /></td>
			<td>備註</td>
			<td><input type = 'text' id = 'profil_input_memo' /></td>
		</tr>,
		<tr>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
	];


	let detail_input_table = {};
	detail_input_table['Profile'] = [
		<tr>
			<td>第一段</td>
			
			<td></td>
			<td>第二段</td>
			<td><input type = 'text' id = 'profil_input_p2' /></td>
			<td></td>
			<td>第三段</td>
			<td><input type = 'text' id = 'profil_input_p3' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'profil_input_memo' /></td>
			<td></td>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
	];

	detail_input_table['Publications'] = [
		<tr>
			<td>第一段</td>
			<td><input type = 'text' id = 'publi_input_p1' /></td>
			<td></td>
			<td>第二段</td>
			<td><input type = 'text' id = 'publi_input_p2' /></td>
			<td></td>
			<td>
				<input type = 'radio' name = 'publi_p2_font' id = 'publi_p2_font_italic' value = '斜體' /> 斜體		
			</td>
			<td>
				<input type = 'radio' name = 'publi_p2_font' id = 'publi_p2_font_bold' value = '粗體' /> 粗體
			</td>
		</tr>,
		<tr>
			<td>第三段</td>
			<td><input type = 'text' id = 'publi_input_p3' /></td>
			<td></td>
			<td>第四段</td>
			<td><input type = 'text' id = 'publi_input_p4' /></td>
			<td></td>
			<td>PDF連結</td>
			<td><input type = 'text' id = 'publi_input_pdf' /></td>
			<td></td>
		</tr>,
		<tr>
			<td>*程式碼</td>
			<td><input type = 'text' id = 'publi_input_code' /></td>
			<td></td>
			<td>備註</td>
			<td><input type = 'text' id = 'publi_input_memo' /></td>
			<td></td>
			<td colSpan = '2'><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			
			
		</tr>

	];

	detail_input_table['Projects'] = [
		<tr>
			<td>單位</td>
			<td><input type = 'text' id = 'proj_input_department' /></td>
			<td></td>
			<td>專案主題</td>
			<td><input type = 'text' id = 'proj_input_subject' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'proj_input_memo' /></td>
			<td></td>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
		</tr>
	];

	detail_input_table['Team'] = [
		<tr>
			<td>次標題</td>
			<td><input type = 'text' id = 'team_input_subtitle' /></td>
			<td></td>
			<td>姓名</td>
			<td><input type = 'text' id = 'team_input_name' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'team_input_memo' /></td>
			<td></td>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
		</tr>
	];

	detail_input_table['Services'] = [
		<tr>
			<td>城市</td>
			<td><input type = 'text' id = 'ser_input_city' /></td>
			<td></td>
			<td>主題</td>
			<td><input type = 'text' id = 'ser_input_subject' /></td>
		</tr>,
		<tr>
			<td>備註</td>
			<td><input type = 'text' id = 'ser_input_memo' /></td>
			<td></td>
			<td><button onClick = {props.input_check_btn_handler}>預覽</button></td>
			<td></td>
		</tr>
	];




	return (
		(props.admin_authenticated === true ?
			<div>
				<br />
				<center><div id = 'input_page_selector_div'>
					<p id = 'renew_title'>請選擇要更新的頁面</p>
					<select onChange = {props.input_page_selected} id = 'input_page_selector'>
						<option selected value = ''>未選擇</option>
						<option value = 'Profile'>學經歷 Profile</option>
						<option value = 'Publications'>發表著作 Publications</option>
						<option value = 'Projects'>研究專案 Projects</option>
						<option value = 'Team'>研究團隊 Team</option>
						<option value = 'Services'>社會服務 Services</option>
					</select>
				</div></center>
				<br />
				{props.input_page === '' ?
					<div></div>

					: <div>

						<center>
							<table>
								<thead></thead>
								<tbody>
									{section_selector}
								</tbody>
							</table>
						</center>

					</div>
				}
				<br />
				{props.input_section === '' ?
					<div></div>
					: (props.input_page === 'Profile' ?
						<div>
							<center>
								<table>
									<thead></thead>
									<tbody>{detail_input_table_profil[props.input_section.split(' ')[props.input_section.split(' ').length - 1]]}</tbody>
								</table>
							</center>
						</div>
						:
						<div>
							<center>
								<table>
									<thead></thead>
									<tbody>
										{detail_input_table[props.input_page]}
									</tbody>
								</table>
							</center>
						</div>
					)
				}
				<br/>
				{props.show_input_check_text === false ?
					<div></div>
					: <div id = 'show_input_check_div'><center>
						<h4>您即將新增：{props.input_page} ({props.input_section.slice(0,2)}) / {helper(props.input_section)}</h4>
						<div>{props.input_preview}</div>
						<h4>請問確定新增嗎？  <button onClick = {props.input_submit}>確認</button></h4>
					</center></div>
				}
			</div>
			: 
			<div>
				<br />
				<br />
				<h4>歡迎進入更新頁面，請進行認證</h4>
				<br />
				<p>帳號：<input type = 'text' id = 'input_user_id' autocomplete = 'off' /></p>
				<p>密碼：<input type = 'password' id = 'input_user_pw' autocomplete = 'off' /></p>
				<br />
				<button onClick = {props.authentication}>確認</button>
			</div>
		)
		
	);
	

}

const helper = (str) => {
	let tmp = str.split(' ');
	tmp.pop();
	return tmp.join(' ').slice(3);
}

export default MAIN_CONTENT_INPUT_FORM;