import TBL_profile_pic from '../img/2018_TBL.jpg';
import TBL_profile_pic_new from '../img/profile_new.JPG';
import ntnu_logo from '../img/logo.gif';
import icon_phone from '../img/phone.png';
import icon_email from '../img/email.png';

import './MAIN_CONTENT_PROFILE_EN.css';



const MAIN_CONTENT_PROFILE_EN = (props) => {


  let intro = [];
  let edu = [];
  let work_exp = [];
  let awards_and_grants = [];
  let research_interests = [];
  let caption_office = [];
  let caption_email = [];
  let caption_call = [];

  //console.log(props.profilEN_DB);

  if (props.profilEN_DB !== undefined) {
    
    if (props.profilEN_DB.intro.length > 0) {
    //  console.log(props.profilEN_DB.intro);
      intro.push(<p className = 'intro' id = 'self-intro'>{props.profilEN_DB.intro[0]['第一段']}</p>);

      for (let i = 1 ; i < props.profilEN_DB.intro.length; i ++) {
      //  console.log('aaaaaaa');
        intro.push(<div className = 'space-small'></div>);
        intro.push(<p className = 'intro'>{props.profilEN_DB.intro[i]['第一段']}</p>);
      }
    }

    if (props.profilEN_DB.edu.length > 0) {
      for (let i = 0 ; i < props.profilEN_DB.edu.length ; i ++) {
        edu.push(<p><span className = 'edu_strong'>{props.profilEN_DB.edu[i]['第一段']}</span>, {props.profilEN_DB.edu[i]['第二段']}</p>);
      }
    }

    if (props.profilEN_DB.work_exp.length > 0) {
      let cur = props.profilEN_DB.work_exp[0];
       
      work_exp.push(<p>{cur['第一段']},<br/>{cur['第二段']}<br/><em>{cur['第三段']}</em></p>);
      for (let i = 1 ; i < props.profilEN_DB.work_exp.length ; i ++) {
         cur = props.profilEN_DB.work_exp[i];
          work_exp.push(<div className = 'space-small'></div>);
         work_exp.push(<p>{cur['第一段']},<br/>{cur['第二段']}<br/><em>{cur['第三段']}</em></p>);
      
      }
    }

    if (props.profilEN_DB.awards_and_grants.length > 0) {
      let cur = props.profilEN_DB.awards_and_grants[0];
      awards_and_grants.push(<tr><td>{cur['第一段']}&nbsp;</td><td>{cur['第二段']}</td></tr>);

      for (let i = 1 ; i < props.profilEN_DB.awards_and_grants.length ; i ++) {
        cur = props.profilEN_DB.awards_and_grants[i];
        awards_and_grants.push(<tr><td>{cur['第一段']}&nbsp;</td><td>{cur['第二段']}</td></tr>);

      }

    }

    //console.log(props.profilEN_DB.research_interests);
    if (props.profilEN_DB.research_interests.length > 0) {

      for (let i = 0 ; i < props.profilEN_DB.research_interests.length ; i ++) {
        let cur = props.profilEN_DB.research_interests[i];
        //console.log(cur);
        research_interests.push(<li>{cur['第一段']}</li>);
      }
      
    }

    if (props.profilEN_DB.caption_office.length > 0) {
      for (let i = 0 ; i < props.profilEN_DB.caption_office.length ; i ++) {
        caption_office.push(<span>{props.profilEN_DB.caption_office[i]['第一段']}</span>);
        if (i !== props.profilEN_DB.caption_office.length - 1) {
          caption_office.push(<br />);
        }
      }

      //console.log(caption_office);
    }
    if (props.profilEN_DB.caption_email.length > 0) {
      for (let i = 0 ; i < props.profilEN_DB.caption_email.length ; i ++) {
        caption_email.push(<span>{props.profilEN_DB.caption_email[i]['第一段']}</span>);
        if (i !== props.profilEN_DB.caption_email.length - 1) {
          caption_email.push(<br />);
        }
      }
    }

    if (props.profilEN_DB.caption_call.length > 0) {
      for (let i = 0 ; i < props.profilEN_DB.caption_call.length ; i ++) {
        caption_call.push(<span>{props.profilEN_DB.caption_call[i]['第一段']}</span>);
        if (i !== props.profilEN_DB.caption_email.length - 1) {
          caption_call.push(<br />);
        }
      }
    }
  }
  

	
	return (
      <div id = 'inner_profile_content_en' className="inner inner-profile">
        <div className="small column">
          <div>
            {/*<img class="scroll-up profile-pic" src="images/2018_TBL.jpg" />*/}
            <img className="scroll-up profile-pic" src = {TBL_profile_pic_new} />
            


            <div className="origin-intro new-intro-table">
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td><img id = 'ntnu_logo' src = {ntnu_logo} /></td>
                    <td><p className = 'origin-intro-text'>{caption_office}</p>
                    {/*
                      <p className="origin-intro-text">

                        
                        Educational Department & <br/>
                        Graduate Institute of Educational Policy Administration, NTNU
                      </p>
                      */}
                      
                    </td>
                  </tr>

                  <tr>
                    <td><img id="email-icon" src = {icon_email} /></td>
                    <td>
                      <p className="origin-intro-text">
                        {caption_email}
                        {/*tzubin_lin@ntnu.edu.tw<br/>
                        odin.london@gmail.com*/}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td><img src = {icon_phone} /></td>
                    <td>
                      <p className = 'origin-intro-text'>
                        {/*02-77343893*/}
                        {caption_call}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="hidden-intro"><p>國立臺灣師範大學<br/>教育學系與教育政策與行政研究所<br/><br/>Tel. <br/>02-77343893<br/><br/>電郵: <br/></p></div>
          </div>
        </div>

        {props.data_finish_loading === false ?
          <div className = 'big column'>

            <h3 id = 'data_loading_text_en'>Data loading...</h3>
            <h4 id = 'data_loading_innertext_en'>This may take few seconds (depends on Internet connectivity)</h4>
          </div>
          :
          <div id = 'introEN' className="big column" onScroll = {props.scrollIntroEN}>
            <br/>{intro}<br/>
            <h3 id="education">Education</h3>{edu}<br/>
            <h3 id="work">Work Experiences</h3>{work_exp}<br/>
            <h3 id="AnG">Awards & Grants</h3><table id="big-table"><tbody>{awards_and_grants}</tbody></table><br/>
            <h3 id="RI">Research Interests</h3><ol>{research_interests}</ol>
            <div className="space"></div>
            <div className="space"></div>
           
          </div>
        }
        
      </div> 
    )

}


export default MAIN_CONTENT_PROFILE_EN;










 {/*<p id="self-intro" className="intro">Dr. Tzu-Bin Lin is an associate professor at the Department of Education and Graduate Institute of Education Policy and Administration, National Taiwan Normal University (NTNU). Prior to this position, he was the full-time learning researcher at Bournemouth University (BU) in the UK and assistant professor in the Policy and Leadership Academic Group in the National Institute of Education (NIE), Singapore.</p>

  <div className="space-small"></div>
  <p className="intro">Dr. Lin’s research interests are in education policy and leadership, media literacy and TESOL. He was the executive editor and editorial board member of the Bulletin of Educational Research. Currently, he is an assistant editor of Cogent Education and editorial board member in several international journals such as Asia TEFL, NAMLE journal of Media Literacy Education, Secondary Education Quarterly (Chinese) and Journal of Educational Research and Development (Chinese).</p>
  <br/>*/}







  {/*
    <p><span><i><b>PhD</b></i></span>, Institute of Education, University of London (2008)</p>
    <p><strong><em>MA in Education Research (with Distinction)</em></strong>, Graduate Institute of Multicultural Education, National Hualien Teachers College (Now – National Donghua University, Taiwan) (2001)</p>
    <p><strong><em>BA (Hons) in Liberal Arts</em></strong>, Department of Foreign Languages and Literature, National Chung-Cheng University, Taiwan (1997)</p>
  
    */}






{/*
  <p>Planning Section Committee Member,<br/>
    The Collaboration Center for Teacher Education, Curriculum, Instruction and Evaluation of Basic Education, The Ministry of Education<br/>
    May, 2017 to present<br/>
    <em>Committee Member</em></p>
  <div className="space-small"></div>
  <p>Division of Intern Programs and Supervision<br/>
    Office of Teacher Education and Careers Service, National Taiwan Normal University, Taiwan <br/>
    Aug 2015 to present <br/>
  <em>Division Head</em></p>
  <div className="space-small"></div>
  <p>Department of Education, National Taiwan Normal University, Taiwan <br/>
    Jan 2017 to Present <br/>
  <em>Associate Professor (Distinguished)</em></p>
  <div className="space-small"></div>
  <p>Department of Education, National Taiwan Normal University, Taiwan <br/>
    Aug 2015 to Dec 2016 <br/>
  <em>Associate Professor</em></p>
  <div className="space-small"></div>
  <p>The Education Bulletin, Department of Education, National Taiwan Normal University, Taiwan <br/>
    Feb 2013 to Dec 2015<br/>
  <em>Executive Editor</em></p>
  <div className="space-small"></div>
  <p>Department of Education, National Taiwan Normal University, Taiwan<br/>
    Feb 2013 – Jul 2015<br/>
  <em>Assistant Professor</em></p>
  <div className="space-small"></div>
  <p>Policy and Leadership Studies Academic Group, National Institute of Education, Singapore <br/>
    Aug 2009 – Jan 2013<br/>
  <em>Assistant Professor</em></p>
  <div className="space-small"></div>
  <p>The Centre for Excellence in Media and Practice, Bournemouth University, UK<br/> 
    Jan 2009 – Jul 2009<br/>
  <em>Learning Researcher</em></p>
*/}








{/*<tr><td>2018&nbsp;</td><td>Visiting Professor, Kyushu University, Japan</td></tr>
              <tr><td>2017&nbsp;</td><td>Visiting Academic, National Institute of Education, Singapore</td></tr>
              <tr><td>2016&nbsp;</td><td>NTNU Distinguished Professorship（2016-2018）</td></tr>
              <tr><td>2015&nbsp;</td><td>NTNU Excellence in Teaching Award</td></tr>
              <tr><td>2013&nbsp;</td><td>NTNU Strategic Recruitment Award</td></tr>
              <tr><td>2007&nbsp;</td><td>MOE Scholarship, Ministry of Education, Taiwan</td></tr>
              <!--tr><td>2006&nbsp;</td><td>MOE Scholarship, Ministry of Education, Taiwan</td></tr>
              <tr><td></td><td>BERA research student bursary</td></tr>
              <tr><td></td><td>Research student grant, Institute of Education</td></tr>
              <tr><td></td><td>Taiwanese research student grant, Ministry of Education, Taiwan</td></tr-->*/}
            












{/*<table id= 'small-table'>
            <tbody>
              <tr><td>2018&nbsp;</td><td>Visiting Professor, Kyushu University, Japan</td></tr>
              <tr><td>2017&nbsp;</td><td>Visiting Academic, </td></tr>
              <tr><td></td><td>National Institute of Education, Singapore</td></tr>
              <tr><td>2016&nbsp;</td><td>NTNU Distinguished</td></tr>
              <tr><td></td><td>Professorship (2016-2018)</td></tr>
              <tr><td>2015&nbsp;</td><td>NTNU Excellence</td></tr>
              <tr><td></td><td>in Teaching Award</td></tr>
              <tr><td>2013&nbsp;</td><td>NTNU Strategic</td></tr>
              <tr><td></td><td>Recruitment Award</td></tr>
              <tr><td>2007&nbsp;</td><td>MOE Scholarship,</td></tr>
              <tr><td></td><td>Ministry of Education, Taiwan</td></tr>
              <!--tr><td>2006&nbsp;</td><td>MOE Scholarship,</td></tr>
              <tr><td></td><td>Ministry of Education, Taiwan</td></tr>
              <tr><td>2006&nbsp;</td><td>BERA research</td></tr>
              <tr><td></td><td>student bursary</td></tr>
              <tr><td>2006&nbsp;</td><td>Research student grant,</td></tr>
              <tr><td></td><td>Institute of Education</td></tr>
              <tr><td>2006&nbsp;</td><td>Taiwanese research student</td></tr>
              <tr><td></td><td>grant, Ministry of Education, Taiwan</td></tr-->
            </tbody>
          </table>*/}





          {/*<li>Education Policy and Leadership and Comparative Education <br/>(with a special focus on comparative policy study in education, principal training and professional development, curriculum leadership and systems thinking),</li>
            <li>Media literacy,</li>
            <li>TESOL (with a focus on Task-based language teaching and Native English Speaking Teachers (NESTs) in Taiwanese context),</li>
            <li>Qualitative methodology.</li>*/}
