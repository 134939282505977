export const PROFILE_DATA_CLEANING = (list) => {
	let to_return_en = {};
	let to_return_ch = {};
	//console.log(list);
	for (let i = 0 ; i < list.length ; i ++) {
		let language = list[i]['系統語言'];
		//console.log(language);

		if (language === '英文') {
			if (to_return_en[list[i]['系統類別']] !== undefined) {
				to_return_en[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_en[list[i]['系統類別']] = [list[i]];
			}
		}

		else if (language === '中文') {
			if (to_return_ch[list[i]['系統類別']] !== undefined) {
				to_return_ch[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_ch[list[i]['系統類別']] = [list[i]];
			}
		}
	}

	//console.log(to_return);
	return [to_return_en, to_return_ch];
}


export const PUBLI_DATA_CLEANING = (list) => {
	
	let to_return_en = {};
	let to_return_ch = {};
	for (let i = 0 ; i < list.length ; i ++) {
		let language = list[i]['系統語言'];

		if (language === '英文') {
			if (to_return_en[list[i]['系統類別']] !== undefined) {
				to_return_en[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_en[list[i]['系統類別']] = [list[i]];
			}
		}

		else if (language === '中文') {
			if (to_return_ch[list[i]['系統類別']] !== undefined) {
				to_return_ch[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_ch[list[i]['系統類別']] = [list[i]];
			}
		}
	}

	//console.log(to_return);
	return [to_return_en, to_return_ch];
}




export const PROJECTS_DATA_CLEANING = (list) => {
	let to_return_en = {};
	let to_return_ch = {};
	for (let i = 0 ; i < list.length ; i ++) {
		let language = list[i]['系統語言'];

		if (language === '英文') {
			if (to_return_en[list[i]['系統類別']] !== undefined) {
				to_return_en[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_en[list[i]['系統類別']] = [list[i]];
			}
		}

		else if (language === '中文') {
			if (to_return_ch[list[i]['系統類別']] !== undefined) {
				to_return_ch[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_ch[list[i]['系統類別']] = [list[i]];
			}
		}
	}

	//console.log(to_return);
	return [to_return_en, to_return_ch];
}


export const TEAM_DATA_CLEANING = (list) => {
	let to_return_en = {};
	let to_return_ch = {};
	for (let i = 0 ; i < list.length ; i ++) {
		let language = list[i]['系統語言'];

		if (language === '英文') {
			if (to_return_en[list[i]['系統類別']] !== undefined) {
				to_return_en[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_en[list[i]['系統類別']] = [list[i]];
			}
		}

		else if (language === '中文') {
			if (to_return_ch[list[i]['系統類別']] !== undefined) {
				to_return_ch[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_ch[list[i]['系統類別']] = [list[i]];
			}
		}
	}

	//console.log(to_return);
	return [to_return_en, to_return_ch];
}


export const SERVICES_DATA_CLEANING = (list) => {
	let to_return_en = {};
	let to_return_ch = {};
	for (let i = 0 ; i < list.length ; i ++) {
		let language = list[i]['系統語言'];

		if (language === '英文') {
			if (to_return_en[list[i]['系統類別']] !== undefined) {
				to_return_en[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_en[list[i]['系統類別']] = [list[i]];
			}
		}

		else if (language === '中文') {
			if (to_return_ch[list[i]['系統類別']] !== undefined) {
				to_return_ch[list[i]['系統類別']].push(list[i]);
			}
			else {
				to_return_ch[list[i]['系統類別']] = [list[i]];
			}
		}
	}

	//console.log(to_return);
	return [to_return_en, to_return_ch];
}



