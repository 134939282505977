import img_project from '../img/project-new.png';
import img_project_new from '../img/projects_new.jpg';

import './MAIN_CONTENT_PROJECTS_CH.css';

const MAIN_CONTENT_PROJECTS_CH = (props) => {


	let all_cat_id = Object.keys(props.projCH_DB);
	let all_cat_content = [];
	let publiNavbar = [];
	for (let i = 0 ; i < all_cat_id.length ; i ++) {
		let ref_id = '#' + all_cat_id[i];
		let ch_id = all_cat_id[i] + '-ch';

		if (props.projCH_DB[all_cat_id[i]][0]['快捷頁籤名稱'] !== undefined) {
			if (props.projCH_DB[all_cat_id[i]][0]['快捷頁籤名稱'] !== '') {
	      		publiNavbar.push(<li><a href = {ref_id}>{props.projCH_DB[all_cat_id[i]][0]['快速頁籤名稱']}</a></li>);
		  	}
	    }
	

		if (props.projCH_DB !== undefined) {
			let proj_dept_subject = {'*': []};
			if (props.projCH_DB[all_cat_id[i]].length > 0) {
			    for (let j = 0 ; j < props.projCH_DB[all_cat_id[i]].length ; j ++) {
			      	let has_dept = false;
			      	let target = props.projCH_DB[all_cat_id[i]][j];

			      	if (target['單位'] !== undefined) {
				        if (target['單位'] !== '') {
				        	has_dept = true;
				        	if (proj_dept_subject[target['單位']] !== undefined) {
				        		proj_dept_subject[target['單位']].push(<li>{target['專案主題']}</li>);
				        	}
				        	else proj_dept_subject[target['單位']] = [<li>{target['專案主題']}</li>];
				        }
				    }

				    if (!has_dept) {
				      	proj_dept_subject['*'].push(<li>{target['專案主題']}</li>);
				    }
				}
			}
			all_cat_content.push(<h3 id = {ch_id}>{props.projCH_DB[all_cat_id[i]][0]['標題名稱']}</h3>);
			all_cat_content.push(<ol>{proj_dept_subject['*']}</ol>);

			for (let item in proj_dept_subject) {
				if (item !== '*') {
					all_cat_content.push(<h4>{item}</h4>);
					all_cat_content.push(<ol>{proj_dept_subject[item]}</ol>);
				}
			}
			all_cat_content.push(<br />);
		}
	}


	let all_publi_content = all_cat_content;


	return (

		<div className="inner inner-projects" id = 'inner_prj_content_ch'>
			<div className="big column" id = 'prjCH' onScroll = {props.scrollPrjCH}>
			<div className ="menu-bar"><ul>{publiNavbar}</ul></div>
      
				<div className="space"></div>
				{all_publi_content}
				<br/><br/><br/>
							
			</div>
			

			<div className="small column">
				<div className="photos">
					<img className="scroll-up" src={img_project_new} />
				</div>

			</div>
		</div>

	);
}

export default MAIN_CONTENT_PROJECTS_CH;












{/*


<h4>科技部</h4>
				<ol>
					<li>臺灣大學生線上論證之研究：提昇全球素養能力之跨領域整合與比較－子計畫四：培養核心素養的學習模式：教育領域大學生如何透過線上論證培養媒體素養能力 (2017-2020)</li>
					<li>臺灣外籍英語教師聘用政策之成效分析：以新北市為例 (2016-2018)</li>
					<li>服務領導與教師專業學習社群之發展：創新國中之民族誌研究 (2014-2016)</li>
					<li>IEA 國際公民教育與素養調查研究（2011-2017）</li>
				</ol>
				<h4>教育部</h4>
				<ol>
					<li>國民及學前教育署學校課程領導人培育計畫 (2014-2017, 2017-2020)</li>
					<li>飛越北歐－瑞典跨域教育見習計畫（2017-2018)</li>
					<li>瑞典跨域移地實習計畫：教育學與心理學的雙向對話。台師大師資培育與就業輔導處 (2017)</li>
					<li>群新爭輝：教育變革下的創新想像。台師大師資培育與就業輔導處 (2017)</li>
					<li>106年海外/境外教學實習 (日本、加拿大、新加坡) (2017)</li>
					<li>106年輔導境外來臺教學實習學生 (加拿大、新加坡) (2017)</li>
					<li>105學年度教育部補助師資培育之大學精緻特色發展計畫-II-2 師資生國際化教學培訓與實習計畫 (2016-2017)</li>
					<li>「新夥」相傳:國立台灣師範大學師資生赴新加坡教育見習計畫 (2016-2017)</li>
					<li>英國教育行政實務增能實習計畫。台師大師資培育與就業輔導處 (2016)</li>
					<li>精緻特色師資發展計畫:計畫推動辦公室</li>
					<li>精緻特色師資發展計畫:認證及駐點駐區計畫</li>
					<li>精進師資素質計畫:展翅高飛之統整學習計畫</li>
					<li>邁向頂尖大學計畫:師資生就業增能計畫</li>
					<li>邁向頂尖大學計畫:推動華人社會師資培育重點大學聯盟</li>
				</ol>
				<h4>文化部財團法人國家電影中心</h4>
				<ol>
					<li>國內電影教育實施現況前導研究 (2017)</li>
					<li>認識電影輔助教材使用現況調查 (2017)</li>
					<li>數位影像時代的教育:電影教育於十二年國民教育階段實施之可能性與策略 (2016)</li>
				</ol>
				<h4>國立臺灣師範大學</h4>
				<ol>
					<li>從「新」開始：台灣與新加坡的學校中層領導之課程領導實踐 (2018)</li>
					<li>國立臺灣師範大學強化與東協及南亞國家合作交流以個別學校辦理之計畫－於「教育」領域，推動見習或實習計畫 (2017)</li>
					<li>當「媒體素養」遇上「健康素養」：以臺灣大學聯盟內的一門跨學科領域媒體素養課程設計為例 (2015-2016)</li>
					<li>臺灣與新加坡多元文化教育政策與論述之比較研究</li>
					{/*<!--li>106年海外/境外教學實習（日本、加拿大、新加坡）(2017)</li>
					<li>106年輔導境外來臺教學實習學生（加拿大、新加坡） (2017)</li>
					<li>華人社會師資培育重點大學聯盟 (2017)</li>
					<li>國立臺灣師範大學強化與東協及南亞國家合作交流以個別學校辦理之計畫－於「教育」領域，推動見習或實習計畫 (2017)</li-->
				</ol>
				<h4>新加坡國立教育學院</h4>
				<ol>
					<li>Mutual Benefits from work-based learning: a case study of the policy issues emerging from the inter-relations of professionals, industry and high education sector (GBP 8,000) PI February 2009 to July 2009 (completed)</li>
				</ol>
				<!--h4>國立屏東大學</h4>
				<ol>
					<li>A Cross-National Study of Principal Preparation and Evaluation System in Elementary Schools and Junior High Schools: Experiences from Singapore, Korea and Taiwan (2010- 2011)</li>
				</ol-->

*/}




{/*<h4>英國協會</h4>
				<ol>
					<li>Investigating NEST schemes around the world: supporting NEST/NNEST collaborative practices. (2014)</li>
				</ol>
				<h4>新加坡國立教育學院</h4>
				<ol>
					<li>The Impact of Community-Based Teacher Learning on Student Learning Outcomes (2012)</li>
					<li>Leadership and Organizational Change (LOC) in Singapore Schools (2010)</li>
					<li>Singapore Youth's Participation in New Media Ecologies - A Pilot Study (2010-2012)</li>
					<li>New Media Literacy of School Students in Singapore (2010- 2012)</li>
				</ol>*/}





{/*


<h3 id="subPI">協同主持人</h3>
				<h4>國家教育研究院</h4>
				<ol>	
					<li>十二年國民基本教育課程綱要實施之課程轉化探究 (2014-2018)</li>
					<div className="space"></div>
					<div className="space"></div>
					<div className="space"></div>
					<div className="space"></div>
					<br/>
				</ol>


*/}
