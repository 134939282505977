import img_team from '../img/team-pic2.png';
import new_img_team from '../img/new_tblin_team.jpg';
import './MAIN_CONTENT_TEAM_EN.css';

const MAIN_CONTENT_TEAM_EN = (props) => {


  let all_cat_id = Object.keys(props.teamEN_DB);
  let all_cat_content = [];
  let publiNavbar = [];
  for (let i = 0 ; i < all_cat_id.length ; i ++) {
    let ref_id = '#' + all_cat_id[i];
    if (props.teamEN_DB[all_cat_id[i]][0]['快捷頁籤名稱'] !== undefined) {
      if (props.teamEN_DB[all_cat_id[i]][0]['快捷頁籤名稱'] !== '') {
        publiNavbar.push(<li><a href = {ref_id}>{props.teamEN_DB[all_cat_id[i]][0]['快捷頁籤名稱']}</a></li>);
      }
    }
    

    if (props.teamEN_DB !== undefined) {
      let team_sub_name = {'*': []};
      if (props.teamEN_DB[all_cat_id[i]].length > 0) {
        
        for (let j = 0 ; j < props.teamEN_DB[all_cat_id[i]].length ; j ++) {
          let has_sub = false;
          let target = props.teamEN_DB[all_cat_id[i]][j];

          if (target['次標題'] !== undefined) {
            if (target['次標題'] !== '') {
              has_sub = true;
              if (team_sub_name[target['次標題']] !== undefined) {
                team_sub_name[target['次標題']].push(<li>{target['姓名']}</li>);
              }
              else team_sub_name[target['次標題']] = [<li>{target['姓名']}</li>];
            }
          }

          if (!has_sub) {
            team_sub_name['*'].push(<li>{target['姓名']}</li>);
          }
        }
      }
      all_cat_content.push(<h3 id = {all_cat_id[i]}>{props.teamEN_DB[all_cat_id[i]][0]['標題名稱']}</h3>);
      all_cat_content.push(<ol>{team_sub_name['*']}</ol>);
      for (let item in team_sub_name) {
        if (item !== '*') {
          all_cat_content.push(<h4>{item}</h4>);
          all_cat_content.push(<ol>{team_sub_name[item]}</ol>);
        }
      }

      all_cat_content.push(<br />);
    }
  }


  let all_publi_content = all_cat_content;

  

	return (
		<div className="inner inner-team" id = 'inner_team_content_en'>
        <div className="small column">
          <div className="photos team-photo">
            <img className="scroll-up" src= {new_img_team}/>
          </div>
        </div>
        <div className="big column" id = 'teamEN' onScroll = {props.scrollTemEN}>
        <div className ="menu-bar"><ul>{publiNavbar}</ul></div>
      
          <div className="space"></div>
          {all_publi_content}
          <br/>
        </div>
      </div>
	);


}

export default MAIN_CONTENT_TEAM_EN;







{/* <h3 id="phd">Doctoral Student</h3>
          <ol>
            <li>黃家凱</li>
          </ol>
          <div className="space"></div>
          <h3 id="ms">Graduate Students</h3>
          <ol>
            <li>李奐穎 (教育的支點:一所高中特色課程發展中教師領導之探究)</li>
            <li>林雍智 (台灣媒體素養教育政策2008~2014分析研究)</li>
            <li>侯仲辰 (臺北市高中英文科跨校專業學習社群教師領導實踐之研究)</li>
    
            <li>徐詩柔 (一所完全中學教師兼行政工作處境之研究) - 與教育系陳佩英教授共同指導</li>
          
            <li>黃彥博 (一所國民中學校長服務領導於學習共同體之實踐)</li>
            <li>孫宇安 (桌上遊戲融入課程領導人培訓之個案研究：以系統思考課程為例)</li>
            <li>江宜芷 (教育改革的媒體再現：以111學年度大學入學方案調整為例)</li>
            <li>潘香汝 (建構以食安議題為核心之國小媒體素養課程方案─以食品添加物為例) - 與教育系劉美慧教授共同指導</li>
          
            <li>洪儀庭</li>
            <li>王慕羽</li>
          </ol>
          <div className="space"></div>
          <h3 id="RA">Research Assistants</h3>
          <p>洪儀庭、徐惠珍、江宜芷、王慕羽、李易蓁、余佩欣、林品慈、黃曼瑄</p>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>*/}
