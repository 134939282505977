import TBL_profile_pic from '../img/2018_TBL.jpg';
import TBL_profile_pic_new from '../img/profile_new.JPG';
import ntnu_logo from '../img/logo.gif';
import icon_phone from '../img/phone.png';
import icon_email from '../img/email.png';

import './MAIN_CONTENT_PROFILE_CH.css';



const MAIN_CONTENT_PROFILE_CH = (props) => {




  let intro = [];
  let edu = [];
  let work_exp = [];
  let awards_and_grants = [];
  let research_interests = [];
  let caption_office = [];
  let caption_email = [];
  let caption_call = [];

  if (props.profilCH_DB !== undefined) {

    if (props.profilCH_DB.intro.length > 0) {
      intro.push(<p className = 'intro' id = 'self-intro-ch'>{props.profilCH_DB.intro[0]['第一段']}</p>);
      for (let i = 1 ; i < props.profilCH_DB.intro.length; i ++) {
        intro.push(<div className = 'space-small'></div>);
        intro.push(<p className = 'intro'>{props.profilCH_DB.intro[i]['第一段']}</p>);
      }
    }

    if (props.profilCH_DB.edu.length > 0) {
      for (let i = 0 ; i < props.profilCH_DB.edu.length ; i ++) {
        edu.push(<p><span className = 'edu_strong'>{props.profilCH_DB.edu[i]['第一段']}</span>, {props.profilCH_DB.edu[i]['第二段']}</p>);
      }
    }

    if (props.profilCH_DB.work_exp.length > 0) {
      let cur = props.profilCH_DB.work_exp[0];
       
      work_exp.push(<p>{cur['第一段']},<br/>{cur['第二段']}<br/><em>{cur['第三段']}</em></p>);
      for (let i = 1 ; i < props.profilCH_DB.work_exp.length ; i ++) {
         cur = props.profilCH_DB.work_exp[i];
          work_exp.push(<div className = 'space-small'></div>);
         work_exp.push(<p>{cur['第一段']},<br/>{cur['第二段']}<br/><em>{cur['第三段']}</em></p>);
      
      }
    }

    if (props.profilCH_DB.awards_and_grants.length > 0) {
      let cur = props.profilCH_DB.awards_and_grants[0];
      awards_and_grants.push(<tr><td>{cur['第一段']}&nbsp;</td><td>{cur['第二段']}</td></tr>);

      for (let i = 1 ; i < props.profilCH_DB.awards_and_grants.length ; i ++) {
        cur = props.profilCH_DB.awards_and_grants[i];
        awards_and_grants.push(<tr><td>{cur['第一段']}&nbsp;</td><td>{cur['第二段']}</td></tr>);

      }

    }

    //console.log(props.profilEN_DB.research_interests);
    if (props.profilCH_DB.research_interests.length > 0) {

      for (let i = 0 ; i < props.profilCH_DB.research_interests.length ; i ++) {
        let cur = props.profilCH_DB.research_interests[i];
       // console.log(cur);
        research_interests.push(<li>{cur['第一段']}</li>);
      }
      
    }

    if (props.profilCH_DB.caption_office.length > 0) {
      for (let i = 0 ; i < props.profilCH_DB.caption_office.length ; i ++) {
        caption_office.push(<span>{props.profilCH_DB.caption_office[i]['第一段']}</span>);
        if (i !== props.profilCH_DB.caption_office.length - 1) {
          caption_office.push(<br />);
        }
      }
    }
    if (props.profilCH_DB.caption_email.length > 0) {
      for (let i = 0 ; i < props.profilCH_DB.caption_email.length ; i ++) {
        caption_email.push(<span>{props.profilCH_DB.caption_email[i]['第一段']}</span>);
        if (i !== props.profilCH_DB.caption_email.length - 1) {
          caption_email.push(<br />);
        }
      }
    }
    if (props.profilCH_DB.caption_call.length > 0) {
      for (let i = 0 ; i < props.profilCH_DB.caption_call.length ; i ++) {
        caption_call.push(<span>{props.profilCH_DB.caption_call[i]['第一段']}</span>);
        if (i !== props.profilCH_DB.caption_call.length - 1) {
          caption_call.push(<br />);
        }
      }
    }
  }
	return (


      <div id = 'inner_profile_content_ch' className="inner inner-profile">
        <div className="small column">
          <div>
            <img className="scroll-up profile-pic" src={TBL_profile_pic_new} />
            <div className="origin-intro new-intro-table">


            <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td><img id = 'ntnu_logo' src = {ntnu_logo} /></td>
                    <td>
                      <p className="origin-intro-text">
                        {caption_office}
                        {/*國立臺灣師範大學<br/>
                        教育學系與教育政策與行政研究所*/}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td><img id="email-icon" src = {icon_email} /></td>
                    <td>
                      <p className="origin-intro-text">
                      {caption_email}
                        {/*tzubin_lin@ntnu.edu.tw<br/>
                        odin.london@gmail.com*/}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td><img src = {icon_phone} /></td>
                    <td>
                      <p className = 'origin-intro-text'>
                      {caption_call}
                        {/*02-77343893*/}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
            <div className="hidden-intro"><p>國立臺灣師範大學<br/>教育學系與教育政策與行政研究所<br/><br/>Tel. <br/>02-77343893<br/><br/>電郵: <br/></p></div>
          </div>
        </div>

        {props.data_finish_loading === false ? 
          <div className = 'big column'>
            <h3 id = 'data_loading_text_ch'>資料載入中，請稍候</h3>
            <h4 id = 'data_loading_innertext_ch'>視網路連線速度，內容應於數秒內自動顯示</h4>
          </div>
          :
          <div className="big column" id = 'introCH' onScroll = {props.scrollIntroCH}>
            <br/>{intro}<br/>
            <h3 id="education">學歷</h3>{edu}<br/>
            <h3 id="work">工作經歷</h3>{work_exp}<br/>   
            <h3 id="AnG">獎勵與補助</h3><table id="big-table"><tbody>{awards_and_grants}</tbody></table><br/>
            <h3 id="RI">學術研究領域</h3><ol>{research_interests}</ol>
            <div className="space"></div>
            <div className="space"></div>
           
            <br/>
          </div>
        }
        
      </div>


    )
}


export default MAIN_CONTENT_PROFILE_CH;













{/*<p className="intro" id="self-intro-ch">林子斌博士目前擔任國立臺灣師範大學教育學系及教育政策與行政研究所副教授。過去曾擔任英國伯恩茅斯大學（Bournemouth University, BU）的全職學習研究員，以及新加坡國立教育學院 (National Institute of Education, NIE) 政策和領導學部的助理教授。</p>
          
          <div className="space-small"></div>
          <p className="intro">林子斌博士的研究興趣包含教育政策與領導、媒體素養以及對外英語教學 (Teachers of English to Speakers of Other Languages, TESOL)。曾擔任教育研究集刊執行編輯和編輯委員會成員，目前則擔任Cogent Journal的助理編輯，同時也是Asia TEFL、國家媒體素養教育協會發行之媒體素養教育期刊 (Journal of Media Literacy Education, National Association for Media Literacy Education, NAMLE) ，中等教育季刊和教育研究與發展期刊等國際學術刊物編輯委員會的委員。</p>
          <br/>





 <p>2008年 倫敦大學教育學院 <strong><em>哲學博士 (Ph.D.)</em></strong></p>
            <p>2001年 國立花蓮師範學院多元文化教育研究所 <strong><em>教育碩士</em></strong></p>
            <p>1997年 國立中正大學外國語文學系 <strong><em>文學士學位</em></strong></p>



<p>教育部 中小學師資課程教學與評量協作中心<br/>
            2017.05 至今<br/>
            <em>規劃委員</em></p>
          <div className="space-small"></div>
          <p>師資培育與就業輔導處 實習輔導組<br/>
            2015.08 至今<br/>
            <em>組長</em></p>
          <div className="space-small"></div>
          <p>國立臺灣師範大學 教育政策與行政研究所<br/>
            2015.08 至今<br/>
          <em>副教授</em></p>
          <div className="space-small"></div>
          <p>國立臺灣師範大學 教育學系 教育研究集刊<br/>
            2013.02 – 2015.10 <br/>
          <em>執行編輯</em></p>
          <div className="space-small"></div>
          <p>國立臺灣師範大學 教育學系<br/>
            2013.02 – 2015.07<br/>
          <em>助理教授</em></p>
          <div className="space-small"></div>
          <p>新加坡國立教育學院主任專業發展班<br/>
            2010.07 - 2012.07<br/>
          <em>主任</em></p>
          <div className="space-small"></div>
          <p>新加坡國立教育學院政策與領導學部<br/>
            2009.08 – 2013.01<br/>
          <em>助理教授</em></p>
          <div className="space-small"></div>
          <p>英國伯恩茅斯大學媒體與實踐傑出研究中心<br/> 
            2009.01 – 2009.07<br/>
          <em>專任研究員</em></p>
          <br/>

<tr><td>2018&nbsp;</td><td>日本九州大學客座教授</td></tr>
              <tr><td>2017&nbsp;</td><td>新加坡國立教育學院訪問學者</td></tr>
              <tr><td>2016&nbsp;</td><td>國立臺灣師範大學優聘教師 (2016-2018)</td></tr>
              <tr><td>2015&nbsp;</td><td>國立臺灣師範大學教學優良獎 (105學年度)</td></tr>
              <!--tr><td>2015&nbsp;</td><td>國立臺灣師範大學研究論文發表獎勵 (104學年度)</td></tr-->
              <tr><td>2013&nbsp;</td><td>國立臺灣師範大學延攬特殊優秀人才獎勵 (103學年度)</td></tr>
              <tr><td>2007&nbsp;</td><td>臺灣教育部公費留學獎學金</td></tr>
              <!--tr><td>2006&nbsp;</td><td>臺灣教育部公費留學獎學金</td></tr>
              <tr><td></td><td>英國教育研究協會(BERA)學生獎學金</td></tr>
              <tr><td></td><td>倫敦大學教育學院學生研究補助</td></tr>
              <tr><td></td><td>教育部臺灣學生研究補助</td></tr-->







              <table id= 'small-table'>
            <tbody>
              <tr><td>2018&nbsp;</td><td>日本九州大學客座教授</td></tr>
              <tr><td>2017&nbsp;</td><td>新加坡國立教育學院訪問學者</td></tr>
              <tr><td>2016&nbsp;</td><td>國立臺灣師範大學</td></tr>
              <tr><td></td><td>優聘教師 (2016-2018)</td></tr>
              <tr><td>2015&nbsp;</td><td>國立臺灣師範大學</td></tr>
              <tr><td></td><td>教學優良獎 (105學年度)</td></tr>
              <!--tr><td>2015&nbsp;</td><td>國立臺灣師範大學</td></tr>
              <tr><td></td><td>研究論文發表獎勵 (104學年度)</td></tr-->
              <tr><td>2013&nbsp;</td><td>國立臺灣師範大學</td></tr>
              <tr><td></td><td>延攬特殊優秀人才獎勵 (103學年度)</td></tr>
              <tr><td>2007&nbsp;</td><td>臺灣教育部公費留學獎學金</td></tr>
              <!--tr><td>2006&nbsp;</td><td>臺灣教育部公費留學獎學金</td></tr>
              <tr><td>2006&nbsp;</td><td>英國教育研究協會(BERA)</td></tr>
              <tr><td></td><td>學生獎學金</td></tr>
              <tr><td>2006&nbsp;</td><td>倫敦大學教育學院</td></tr>
              <tr><td></td><td>學生研究補助</td></tr>
              <tr><td>2006&nbsp;</td><td>教育部臺灣學生研究補助</td></tr-->
            </tbody>
          </table>




          <li>教育政策與領導<br/>(特別聚焦在教育政策的比較研究、校長培訓課程與專業發展、課程領導、學習型組織理論與實作、專業學習社群)</li>
            <li>媒介素養教育 <br/>(新媒介與學習)</li>
            <li>英語教學<br/>(特別聚焦在以任務為導向的語言教學以及在華人社會下以英語為母語的英語教師)</li>
            <li>教育研究法<br/>(教育研究方法論、質性研究方法)</li>
          

*/}