import MAIN_CONTENT_PROFILE_EN from '../MAIN_CONTENT_PROFILE_EN/MAIN_CONTENT_PROFILE_EN';
import MAIN_CONTENT_PROFILE_CH from '../MAIN_CONTENT_PROFILE_CH/MAIN_CONTENT_PROFILE_CH';


const MAIN_CONTENT_PROFILE = (props) => {

  switch (props.nowLanguage) {
    case 'EN':
      return (
        <MAIN_CONTENT_PROFILE_EN 
          scrollIntroEN = {props.scrollIntroEN}
          profilEN_DB = {props.profil_DB[0]}

          data_finish_loading = {props.data_finish_loading}
        />
      );
      break;

    case 'CH':
      return (
        <MAIN_CONTENT_PROFILE_CH 
          scrollIntroCH = {props.scrollIntroCH}
          profilCH_DB = {props.profil_DB[1]}

          data_finish_loading = {props.data_finish_loading}
        />
      );
      break;

    default:
      return (<div></div>);
  }
  
}


export default MAIN_CONTENT_PROFILE;