import icon_email_red from '../img/email-red.png';
import icon_phone_red from '../img/phone-red.png';

const FOOTER_CH = (props) => {
	return (

		<div className="footer">


		<div className = 'footerTitle'>林子斌 / 國立臺灣師範大學</div>
		<div className = 'footerIcon'>	
			<a href="mailto: tzubin_lin@ntnu.edu.tw, odin.london@gmail.com"> 
				<img className="footer-icon" src= {icon_email_red} />
			</a>

			<a href="tel:+886-02-7734-3893">
				<img className="footer-icon" src= {icon_phone_red} />
			</a>
	     </div>
	      <div className = 'switch' onClick = {props.changeLanguage}>
	      	<button id = 'btnCH'>中文</button>
	      	<button id = 'btnEN'>English</button>

	      </div>

		</div>

	);
}

export default FOOTER_CH;