import logo from './logo.svg';
import pdf_icon from './img/pdf-icon.png';
import './App.css';
import React, {Component, useEffect, useState} from 'react';
import { GoogleSpreadsheet } from "google-spreadsheet";

import HEADER from './HEADER/HEADER';
import MAIN_CONTENT from './MAIN_CONTENT/MAIN_CONTENT';
import FOOTER from './FOOTER/FOOTER';


import {PROFILE_DATA_CLEANING, PUBLI_DATA_CLEANING, PROJECTS_DATA_CLEANING, TEAM_DATA_CLEANING, SERVICES_DATA_CLEANING} from './DATA_CLEANING/DATA_CLEANING';



class App extends Component {

  state = {

    nowPage: 'Profile',
    nowLanguage: 'EN',


    introENScrollTopThres: -100,
    introENScrollTop: 0,
    introCHScrollTopThres: -100,
    introCHScrollTop: 0,


    publiENScrollTopThres: -100,
    publiENScrollTop: 0,
    publiCHScrollTopThres: -100,
    publiCHScrollTop: 0,
    publiENProfilPicUp: false,
    publiCHProfilPicup: false,

    prjENScrollTopThres: -100,
    prjENScrollTop: 0,
    prjCHScrollTopThres: -100,
    prjCHScrollTop: 0,

    teamENScrollTopThres: -100,
    teamENScrollTop: 0,
    teamCHScrollTopThres: -100,
    teamCHScrollTop: 0,

    serENScrollTopThres: -100,
    serENScrollTop: 0,
    serCHScrollTopThres: -100,
    serCHScrollTop: 0,
  
    profil_DB: [],
    profil_first_en_id: '',
    profil_first_ch_id: '',

    publi_DB: [],
    publi_first_en_id: '',
    publi_first_ch_id: '',
    
    proj_DB: [],
    proj_first_en_id: '',
    proj_first_ch_id: '',

    team_DB: [],
    team_first_en_id: '',
    team_first_ch_id: '',

    ser_DB: [],
    ser_first_en_id: '',
    ser_first_ch_id: '',

    input_page: '',
    input_section: '',
    input_preview: [],

    show_input_check_text: false,

    data_finish_loading: false,

    admin_id_pw: [],
    admin_authenticated: false
  
  }


  async componentDidMount () {
    const doc = new GoogleSpreadsheet('10RbLqqMM_hU9wsB4ROexwjlbxytBj_bgqsJ7ZlpgGYY');
    await doc.useServiceAccountAuth(require('./CONST/tzubinlin-web-329402-bd489671740f.json'));
    await doc.loadInfo();
    

    const profil = doc.sheetsByTitle['學經歷'];
    const profil_list_row = await profil.getRows();
    const profil_clean_list_row = PROFILE_DATA_CLEANING(profil_list_row);


    const publi = doc.sheetsByTitle['發表著作'];
    const publi_list_row = await publi.getRows(); 
    const publi_clean_list_row = PUBLI_DATA_CLEANING(publi_list_row); 
    const publi_first_en_id = Object.keys(publi_clean_list_row[0])[0];
    const publi_first_ch_id = Object.keys(publi_clean_list_row[1])[0] + '-ch';


    const proj = doc.sheetsByTitle['研究專案'];
    const proj_list_row = await proj.getRows();
    const proj_clean_list_row = PROJECTS_DATA_CLEANING(proj_list_row);
    const proj_first_en_id = Object.keys(proj_clean_list_row[0])[0];
    const proj_first_ch_id = Object.keys(proj_clean_list_row[1])[0] + '-ch';


    const team = doc.sheetsByTitle['研究團隊'];
    const team_list_row = await team.getRows();
    const team_clean_list_row = TEAM_DATA_CLEANING(team_list_row);
    const team_first_en_id = Object.keys(team_clean_list_row[0])[0];
    const team_first_ch_id = Object.keys(team_clean_list_row[1])[0] + '-ch';
    

    const ser = doc.sheetsByTitle['社會服務'];
    const ser_list_row = await ser.getRows();
    const ser_clean_list_row = SERVICES_DATA_CLEANING(ser_list_row);
    const ser_first_en_id = Object.keys(ser_clean_list_row[0])[0];
    const ser_first_ch_id = Object.keys(ser_clean_list_row[1])[0] + '-ch';

    const id_pw = doc.sheetsByTitle['前台更新帳號密碼設定'];
    const id_pw_list_row = await id_pw.getRows();
    let admin_id_pw = [];


    //console.log(id_pw_list_row);
    for (let i = 0 ; i < id_pw_list_row.length ; i ++) {
      let admin_id = '';
      let admin_pw = '';
      if (id_pw_list_row[i]['帳號'] !== undefined) admin_id = id_pw_list_row[i]['帳號'];
      if (id_pw_list_row[i]['密碼'] !== undefined) admin_pw = id_pw_list_row[i]['密碼'];
      admin_id_pw.push([admin_id, admin_pw]);
    }


    this.setState({
      profil_DB: profil_clean_list_row,
      publi_DB: publi_clean_list_row,
      publi_first_en_id: publi_first_en_id,
      publi_first_ch_id: publi_first_ch_id,
      proj_DB: proj_clean_list_row,
      proj_first_en_id: proj_first_en_id,
      proj_first_ch_id: proj_first_ch_id,
      team_DB: team_clean_list_row,
      team_first_en_id: team_first_en_id,
      team_first_ch_id: team_first_ch_id,
      ser_DB: ser_clean_list_row,
      ser_first_en_id: ser_first_en_id,
      ser_first_ch_id: ser_first_ch_id,

      data_finish_loading: true,

      admin_id_pw: admin_id_pw
    });
  }

  componentWillUnmount() {
    
  }



  // intro
  scrollIntroEN = () => {
    let introENScrollTop = document.getElementById('self-intro').getBoundingClientRect().top;
    if (introENScrollTop < this.state.introENScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_profile_content_en').classList.add('bigger-content');
    }
    else {
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
        document.getElementById('headEN').classList.remove('smaller-head');
        document.getElementById('inner_profile_content_en').classList.remove('bigger-content');
    }


    this.setState({
      introENScrollTop: introENScrollTop
    });
  }

  scrollIntroCH = () => {
    let introCHScrollTop = document.getElementById('self-intro-ch').getBoundingClientRect().top;
    //console.log(introCHScrollTop);
    //console.log('aa');

    if (introCHScrollTop < this.state.introCHScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_profile_content_ch').classList.add('bigger-content');
    }
    else {
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
        document.getElementById('headEN').classList.remove('smaller-head');
        document.getElementById('inner_profile_content_ch').classList.remove('bigger-content');
    }
    this.setState({
      introCHScrollTop: introCHScrollTop
    });
  }



  // publications
  scrollPubliEN = () => {


    //let publiENScrollTop = document.getElementById('journal-papers').getBoundingClientRect().top;
    let test = 'jp';
    //console.log(this.state.publi_DB_first_id);
    let publiENScrollTop = document.getElementById(this.state.publi_first_en_id).getBoundingClientRect().top;
   
    //console.log(publiENScrollTop);

    if (publiENScrollTop < this.state.publiENScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_publi_content_en').classList.add('bigger-content');
    }
    else {
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
        document.getElementById('headEN').classList.remove('smaller-head');
        document.getElementById('inner_publi_content_en').classList.remove('bigger-content');
    }


    this.setState({
      publiENScrollTop: publiENScrollTop
    });
  }

  scrollPubliCH = () => {
    let publiCHScrollTop = document.getElementById(this.state.publi_first_ch_id).getBoundingClientRect().top;
    //console.log(introCHScrollTop);
    //console.log('aa');

    if (publiCHScrollTop < this.state.publiCHScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_publi_content_ch').classList.add('bigger-content');
    }
    else {
      document.getElementById('main_scrollable_content').classList.remove('bigger-content');
      document.getElementById('headEN').classList.remove('smaller-head');
      document.getElementById('inner_publi_content_ch').classList.remove('bigger-content');
    }
    this.setState({
      publiCHScrollTop: publiCHScrollTop
    });
  }





   // projects
  scrollPrjEN = () => {


    let prjENScrollTop = document.getElementById(this.state.proj_first_en_id).getBoundingClientRect().top;
    //console.log(publiENScrollTop);

    if (prjENScrollTop < this.state.prjENScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_prj_content_en').classList.add('bigger-content');
    }
    else {
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
        document.getElementById('headEN').classList.remove('smaller-head');
        document.getElementById('inner_prj_content_en').classList.remove('bigger-content');
    }


    this.setState({
      publiENScrollTop: prjENScrollTop
    });
  }

  scrollPrjCH = () => {
    let prjCHScrollTop = document.getElementById(this.state.proj_first_ch_id).getBoundingClientRect().top;
    //console.log(introCHScrollTop);
    //console.log('aa');

    if (prjCHScrollTop < this.state.prjCHScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_prj_content_ch').classList.add('bigger-content');
    }
    else {
      document.getElementById('main_scrollable_content').classList.remove('bigger-content');
      document.getElementById('headEN').classList.remove('smaller-head');
      document.getElementById('inner_prj_content_ch').classList.remove('bigger-content');
    }
    this.setState({
      publiCHScrollTop: prjCHScrollTop
    });
  }





   // team
  scrollTemEN = () => {


    let teamENScrollTop = document.getElementById(this.state.team_first_en_id).getBoundingClientRect().top;
    //console.log(publiENScrollTop);

    if (teamENScrollTop < this.state.teamENScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_team_content_en').classList.add('bigger-content');
    }
    else {
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
        document.getElementById('headEN').classList.remove('smaller-head');
        document.getElementById('inner_team_content_en').classList.remove('bigger-content');
    }


    this.setState({
      teamENScrollTop: teamENScrollTop
    });
  }

  scrollTemCH = () => {
    let teamCHScrollTop = document.getElementById(this.state.team_first_ch_id).getBoundingClientRect().top;
    //console.log(introCHScrollTop);
    //console.log('aa');

    if (teamCHScrollTop < this.state.teamCHScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_team_content_ch').classList.add('bigger-content');
    }
    else {
      document.getElementById('main_scrollable_content').classList.remove('bigger-content');
      document.getElementById('headEN').classList.remove('smaller-head');
      document.getElementById('inner_team_content_ch').classList.remove('bigger-content');
    }
    this.setState({
      teamCHScrollTop: teamCHScrollTop
    });
  }





   // team
  scrollSerEN = () => {


    let serENScrollTop = document.getElementById(this.state.ser_first_en_id).getBoundingClientRect().top;
    //console.log(publiENScrollTop);

    if (serENScrollTop < this.state.serENScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_services_content_en').classList.add('bigger-content');
    }
    else {
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
        document.getElementById('headEN').classList.remove('smaller-head');
        document.getElementById('inner_services_content_en').classList.remove('bigger-content');
    }


    this.setState({
      serENScrollTop: serENScrollTop
    });
  }

  scrollSerCH = () => {
    let serCHScrollTop = document.getElementById(this.state.ser_first_ch_id).getBoundingClientRect().top;
    //console.log(introCHScrollTop);
    //console.log('aa');

    if (serCHScrollTop < this.state.serCHScrollTopThres) {
      document.getElementById('main_scrollable_content').classList.add('bigger-content');
      document.getElementById('headEN').classList.add('smaller-head');
      document.getElementById('inner_services_content_ch').classList.add('bigger-content');
    }
    else {
      document.getElementById('main_scrollable_content').classList.remove('bigger-content');
      document.getElementById('headEN').classList.remove('smaller-head');
      document.getElementById('inner_services_content_ch').classList.remove('bigger-content');
    }
    this.setState({
      serCHScrollTop: serCHScrollTop
    });
  }





  //

 
  to_input_page = () => {
    this.setState({
      nowPage: 'InputForm',
      input_page: '',
      input_section: '',
      show_input_check_text: false
    });
  }

  changePage = (e) => {

    if (this.state.data_finish_loading) {
      let oriPageName = this.state.nowPage;
      let newPageName = e.currentTarget.id;
      //console.log(e.currentTarget.id);




      document.getElementById('main_scrollable_content').classList.remove('bigger-content');
      document.getElementById('headEN').classList.remove('smaller-head');


      if (this.state.nowLanguage === 'EN') {

        if (oriPageName === 'Profile') {
          document.getElementById('inner_profile_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Publications') {
          document.getElementById('inner_publi_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Projects') {
          document.getElementById('inner_prj_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Team') {
          document.getElementById('inner_team_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Services') {
          document.getElementById('inner_services_content_en').classList.remove('bigger-content');
        }
      }
      else if (this.state.nowLanguage === 'CH') {
        if (oriPageName === 'Profile') {
          document.getElementById('inner_profile_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Publications') {
          document.getElementById('inner_publi_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Projects') {
          document.getElementById('inner_prj_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Team') {
          document.getElementById('inner_team_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Services') {
          document.getElementById('inner_services_content_ch').classList.remove('bigger-content');
        }

      }
    

      this.setState({
        nowPage: newPageName,
        introENScrollTop: 0,
        introCHScrollTop: 0,
        publiENScrollTop: 0,
        publiCHScrollTop: 0,
        prjENScrollTop: 0,
        prjCHScrollTop: 0,
        teamENScrollTop: 0,
        teamCHScrollTop: 0,
        serENScrollTop: 0,
        serCHScrollTop: 0
      })
 
    }
       
    //console.log(newPageName);
  }
 

  changeLanguage = (e) => {

    /*console.log(document.getElementById('introEN').getBoundingClientRect().top);*/

    let btnClicked = e.target.id;
    if (btnClicked.length === 5) {
       let newLanguage = btnClicked.slice(3);
       let oriPageName = this.state.nowPage;

       //console.log(oriPageName, this.state.nowLanguage);
       document.getElementById('main_scrollable_content').classList.remove('bigger-content');
      document.getElementById('headEN').classList.remove('smaller-head');

       if (this.state.nowLanguage === 'EN') {

        if (oriPageName === 'Profile') {
          //console.log('aaa');
          document.getElementById('inner_profile_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Publications') {
          document.getElementById('inner_publi_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Projects') {
          document.getElementById('inner_prj_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Team') {
          document.getElementById('inner_team_content_en').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Services') {
          document.getElementById('inner_services_content_en').classList.remove('bigger-content');
        }
      }
      else if (this.state.nowLanguage === 'CH') {
        if (oriPageName === 'Profile') {
          document.getElementById('inner_profile_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Publications') {
          document.getElementById('inner_publi_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Projects') {
          document.getElementById('inner_prj_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Team') {
          document.getElementById('inner_team_content_ch').classList.remove('bigger-content');
        }
        else if (oriPageName === 'Services') {
          document.getElementById('inner_services_content_ch').classList.remove('bigger-content');
        }

      }
       if (newLanguage !== this.state.nowLanguage) {
        this.setState({
          nowLanguage: newLanguage,
          introENScrollTop: 0,
          introCHScrollTop: 0,
          publiENScrollTop: 0,
          publiCHScrollTop: 0,
          prjENScrollTop: 0,
          prjCHScrollTop: 0,
          teamENScrollTop: 0,
          teamCHScrollTop: 0,
          serENScrollTop: 0,
          serCHScrollTop: 0,
        })
       }

     }
    
  }

  input_check_btn_handler = () => {

    let input_preview = [];

    switch (this.state.input_page) {

      case 'Profile':
        let profil_first = '';
        if (document.getElementById('profil_input_p1') !== null) profil_first = document.getElementById('profil_input_p1').value;
        let profil_second = '';
        if (document.getElementById('profil_input_p2') !== null) profil_second = document.getElementById('profil_input_p2').value;
        let profil_third = '';
        if (document.getElementById('profil_input_p3') !== null) profil_third = document.getElementById('profil_input_p3').value;
        
        let sect = this.state.input_section.split(' ');
        switch(sect[sect.length -1]) {
          case 'intro':
            if (profil_first !== '') input_preview.push(<p>{profil_first}</p>);
            break;

          case 'edu':
            if (profil_first !== '' && profil_second !== '') input_preview.push(<p><strong><i>{profil_first}</i></strong>, {profil_second}</p>);
            else if (profil_first !== '') input_preview.push(<p><strong><i>{profil_first}</i></strong></p>);
            break;

          case 'work_exp':
            if (profil_first !== '' && profil_second !== '' && profil_third !== '') {
              input_preview.push(<p>{profil_first},<br/>{profil_second}<br/><em>{profil_third}</em></p>);
            }
            else input_preview.push(<p>請檢查未填寫欄位！</p>);
            break;

          case 'awards_and_grants':
            if (profil_first !== '' && profil_second !== '') {
              input_preview.push(<table id = 'ag_preview'><tbody><tr><td>{profil_first}</td><td>{profil_second}</td></tr></tbody></table>);
            }
            else input_preview.push(<p>請檢查未填寫欄位！</p>);
            break;

          case 'research_interests':
            if (profil_first !== '') input_preview.push(<p>{profil_first}</p>);
            else input_preview.push(<p>請檢查未填寫欄位！</p>);
            break;

          default:
            break;
        }
        break;

      case 'Publications':
        let first = document.getElementById('publi_input_p1').value;
        let second = document.getElementById('publi_input_p2').value;
        let second_font = '斜體';
        if (document.getElementById('publi_p2_font_bold').checked) {
          second_font = '粗體';
        }
        
        let third = document.getElementById('publi_input_p3').value;
        let fourth = document.getElementById('publi_input_p4').value;
        let pdf = document.getElementById('publi_input_pdf').value;
        let memo = document.getElementById('publi_input_memo').value;
        let code = document.getElementById('publi_input_code').value;

        if (code === '') {
          if (first !== '') {
            if (this.state.input_section.slice(0,2) === '英文') {
              if (first.includes('Lin, T.-B.')) {
                let tmp = first.split('Lin, T.-B.');
                input_preview.push(tmp[0]);
                input_preview.push(<strong>Lin, T.-B.</strong>);
                input_preview.push(tmp[1]);
              }
              else input_preview.push(first);
            }
            else if (this.state.input_section.slice(0,2) === '中文') {
              if (first.includes('林子斌')) {
                let tmp = first.split('林子斌');
                input_preview.push(tmp[0]);
                input_preview.push(<strong>林子斌</strong>);
                input_preview.push(tmp[1]);
              }
              else input_preview.push(first);
            }
          }
          if (second !== '') {
            if (second_font === '斜體') {
              input_preview.push(<i>{second}</i>);
            }
            else if (second_font === '粗體') {
              input_preview.push(<strong>{second}</strong>);
            }
            {/*
            if (this.state.input_section.slice(0,2) === '英文') {
              input_preview.push(<i>{second}</i>);
            }
            else if (this.state.input_section.slice(0,2) === '中文') {
              input_preview.push(<strong>{second}</strong>);
            }
            */}
          }
          if (third !== '') input_preview.push(third);
          if (fourth !== '') input_preview.push(<strong>{fourth}</strong>);
          if (pdf !== '') input_preview.push(<a href = {pdf} target = '_blank'><img src = {pdf_icon} /></a>);
        }

        // with code
        else {
          let arr_ref_main = code.split('#');
          for (let idx = 0 ; idx < arr_ref_main.length ; idx ++) {
            if (arr_ref_main[idx].length > 2) {
              if (arr_ref_main[idx].slice(0,2) === 'bf') {
                input_preview.push(<strong>{arr_ref_main[idx].slice(3)}</strong>);
              }
              else if (arr_ref_main[idx].slice(0,2) === 'sl') {
                input_preview.push(<i>{arr_ref_main[idx].slice(3)}</i>);
              }
              else input_preview.push(arr_ref_main[idx]);
            }
            else input_preview.push(arr_ref_main[idx]);
          }
          if (pdf !== '') input_preview.push(<a href = {pdf} target = '_blank'><img src = {pdf_icon} /></a>);
        }
        break;

      case 'Projects':
        let proj_department = document.getElementById('proj_input_department').value;
        let proj_subject = document.getElementById('proj_input_subject').value;
        if (proj_department !== '') input_preview.push(<h4>{proj_department}</h4>);
        if (proj_subject !== '') input_preview.push(<p>{proj_subject}</p>);
        break;

      case 'Team':
        let team_subtitle = document.getElementById('team_input_subtitle').value;
        let team_name = document.getElementById('team_input_name').value;
        if (team_subtitle !== '') input_preview.push(<h4>{team_subtitle}</h4>);
        if (team_name !== '') input_preview.push(<h4>{team_name}</h4>);
        break;

      case 'Services':
        let ser_city = document.getElementById('ser_input_city').value;
        let ser_subject = document.getElementById('ser_input_subject').value;
        if (ser_city !== '') input_preview.push(<h4>{ser_city}</h4>);
        if (ser_subject !== '') input_preview.push(<p>{ser_subject}</p>);

      default: 
        break;
    }

    this.setState({
      show_input_check_text: true,
      input_preview: input_preview
    });
  }


  input_page_selected = () => {
    let input_page_selected = document.getElementById('input_page_selector').value;
    //console.log(input_page_selected);
    this.setState({
      input_page: input_page_selected,
      input_section: '',
      show_input_check_text: false
    });
  }

  input_section_selected = () => {
    let input_section_selected = '';
    switch (this.state.input_page) {
      case 'Profile':
        input_section_selected = document.getElementById('input_section_selector_profile').value;
        break;

      case 'Publications':
        input_section_selected = document.getElementById('input_section_selector_publications').value;
        break;

      case 'Projects':
        input_section_selected = document.getElementById('input_section_selector_projects').value;
        break;

      case 'Team':
        input_section_selected = document.getElementById('input_section_selector_team').value;
        break;

      case 'Services':
        input_section_selected = document.getElementById('input_section_selector_services').value;
        break;

      default:
        break;
    }

    //console.log(input_section_selected);
    this.setState({
      input_section: input_section_selected
    });
  }


  input_submit = () => {

    let appendSpreadsheet_profil;
    let appendSpreadsheet_publi;
    let appendSpreadsheet_proj;
    let appendSpreadsheet_team;
    let appendSpreadsheet_ser;

    switch(this.state.input_page) {

      case 'Profile':
        const doc_profil = new GoogleSpreadsheet('10RbLqqMM_hU9wsB4ROexwjlbxytBj_bgqsJ7ZlpgGYY');   
        appendSpreadsheet_profil = async (row) => {
          try {
            await doc_profil.useServiceAccountAuth(require('./CONST/tzubinlin-web-329402-bd489671740f.json'));  
            await doc_profil.loadInfo();
            const sheet_profil = doc_profil.sheetsByTitle['學經歷'];
            const result = await sheet_profil.addRow(row);
          } catch (e) {
            console.error('Error: ', e);
          }
        };
        let profil_tmp = this.state.input_section.split(' ');
        let profil_sys_language = profil_tmp.shift();
        let profil_sys_category = profil_tmp.pop();
        let profil_title = profil_tmp.join(' ');
        
        let profil_first = '';
        if (document.getElementById('profil_input_p1') !== null) profil_first = document.getElementById('profil_input_p1').value;
        let profil_second = '';
        if (document.getElementById('profil_input_p2') !== null) profil_second = document.getElementById('profil_input_p2').value;
        let profil_third = '';
        if (document.getElementById('profil_input_p3') !== null) profil_third = document.getElementById('profil_input_p3').value;
        let profil_memo = '';
        if (document.getElementById('profil_input_memo') !== null) profil_memo = document.getElementById('profil_input_memo').value;

        const profil_newRow = {
          "系統語言": profil_sys_language,
          "系統類別": profil_sys_category,
          "標題名稱": profil_title,
          "第一段": profil_first,
          "第二段": profil_second,
          "第三段": profil_third,
          "備註": profil_memo
        }
        appendSpreadsheet_profil(profil_newRow);
        alert('Profile content updated!');
        break;

      case 'Publications':
        const doc_publi = new GoogleSpreadsheet('10RbLqqMM_hU9wsB4ROexwjlbxytBj_bgqsJ7ZlpgGYY');   
        appendSpreadsheet_publi = async (row) => {
          try {
            await doc_publi.useServiceAccountAuth(require('./CONST/tzubinlin-web-329402-bd489671740f.json'));  
            await doc_publi.loadInfo();
            const sheet_publi = doc_publi.sheetsByTitle['發表著作'];
            const result = await sheet_publi.addRow(row);
          } catch (e) {
            console.error('Error: ', e);
          }
        };
        let publi_tmp = this.state.input_section.split(' ');
        let publi_sys_language = publi_tmp.shift();
        let publi_sys_category = publi_tmp.pop();
        let publi_title = publi_tmp.join(' ');

        //console.log(this.state.publi_DB[0]);
        let publi_tag_name = this.state.publi_DB[0][publi_sys_category][0]['快捷頁籤名稱'];
        if (publi_sys_language === '中文') publi_tag_name = this.state.publi_DB[1][publi_sys_category][0]['快捷頁籤名稱'];
        let publi_first = document.getElementById('publi_input_p1').value;
        let publi_second = document.getElementById('publi_input_p2').value;
        let publi_second_font = '斜體';
        if (document.getElementById('publi_p2_font_bold').checked) publi_second_font = '粗體';

        let publi_third = document.getElementById('publi_input_p3').value;
        let publi_fourth = document.getElementById('publi_input_p4').value;
        let publi_pdf = document.getElementById('publi_input_pdf').value;
        let publi_memo = document.getElementById('publi_input_memo').value;
        let publi_code = document.getElementById('publi_input_code').value;

        const publi_newRow = {
          "系統語言": publi_sys_language,
          "系統類別": publi_sys_category,
          "快捷頁籤名稱": publi_tag_name,
          "標題名稱": publi_title,
          "第一段": publi_first,
          "第二段": publi_second,
          "第三段": publi_third,
          "第四段": publi_fourth,
          "程式碼格式": publi_code,
          "第二段字體": publi_second_font,
          "PDF連結": publi_pdf,
          "備註": publi_memo
        }
        appendSpreadsheet_publi(publi_newRow);
        alert('Publications content updated!');
        break;

      case 'Projects':
        const doc_proj = new GoogleSpreadsheet('10RbLqqMM_hU9wsB4ROexwjlbxytBj_bgqsJ7ZlpgGYY');   
        appendSpreadsheet_proj = async (row) => {
          try {
            await doc_proj.useServiceAccountAuth(require('./CONST/tzubinlin-web-329402-bd489671740f.json'));  
            await doc_proj.loadInfo();
            const sheet_proj = doc_proj.sheetsByTitle['研究專案'];
            const result = await sheet_proj.addRow(row);
          } catch (e) {
            console.error('Error: ', e);
          }
        };
        let proj_tmp = this.state.input_section.split(' ');
        let proj_sys_language = proj_tmp.shift();
        let proj_sys_category = proj_tmp.pop();
        let proj_title = proj_tmp.join(' ');
        let proj_tag_name = '';
        if (proj_sys_language === '英文') {
          //console.log(this.state.proj_DB[0], proj_sys_category);
          if (this.state.proj_DB[0][proj_sys_category][0] !== undefined) {
            if (this.state.proj_DB[0][proj_sys_category][0]['快捷頁籤名稱'] !== undefined) {
              proj_tag_name = this.state.proj_DB[0][proj_sys_category][0]['快捷頁籤名稱'];
            }  
          }
        }
        else if (proj_sys_language === '中文') {
          if (this.state.proj_DB[1][proj_sys_category][0] !== undefined) {
            if (this.state.proj_DB[1][proj_sys_category][0]['快捷頁籤名稱'] !== undefined) {
              proj_tag_name = this.state.proj_DB[1][proj_sys_category][0]['快捷頁籤名稱'];
            } 
          }
        }
        let proj_department = document.getElementById('proj_input_department').value;
        let proj_subject = document.getElementById('proj_input_subject').value;
        let proj_memo = document.getElementById('proj_input_memo').value;
       
        const proj_newRow = {
          "系統語言": proj_sys_language,
          "系統類別": proj_sys_category,
          "快捷頁籤名稱": proj_tag_name,
          "標題名稱": proj_title,
          "單位": proj_department,
          "專案主題": proj_subject,
          "備註": proj_memo
        }
        appendSpreadsheet_proj(proj_newRow);
        alert('Projects content updated!');
        break;

      case 'Team':
        const doc_team = new GoogleSpreadsheet('10RbLqqMM_hU9wsB4ROexwjlbxytBj_bgqsJ7ZlpgGYY');   
        appendSpreadsheet_team = async (row) => {
          try {
            await doc_team.useServiceAccountAuth(require('./CONST/tzubinlin-web-329402-bd489671740f.json'));  
            await doc_team.loadInfo();
            const sheet_team = doc_team.sheetsByTitle['研究團隊'];
            const result = await sheet_team.addRow(row);
          } catch (e) {
            console.error('Error: ', e);
          }
        };
        let team_tmp = this.state.input_section.split(' ');
        let team_sys_language = team_tmp.shift();
        let team_sys_category = team_tmp.pop();
        let team_title = team_tmp.join(' ');
        let team_tag_name = '';
        if (team_sys_language === '英文') {
          //console.log(this.state.proj_DB[0], proj_sys_category);
          if (this.state.team_DB[0][team_sys_category][0] !== undefined) {
            if (this.state.team_DB[0][team_sys_category][0]['快捷頁籤名稱'] !== undefined) {
              team_tag_name = this.state.team_DB[0][team_sys_category][0]['快捷頁籤名稱'];
            }  
          }
        }
        else if (team_sys_language === '中文') {
          if (this.state.team_DB[1][team_sys_category][0] !== undefined) {
            if (this.state.team_DB[1][team_sys_category][0]['快捷頁籤名稱'] !== undefined) {
              team_tag_name = this.state.team_DB[1][team_sys_category][0]['快捷頁籤名稱'];
            } 
          }
        }
        let team_subtitle = document.getElementById('team_input_subtitle').value;
        let team_name = document.getElementById('team_input_name').value;
        let team_memo = document.getElementById('team_input_memo').value;
       
        const team_newRow = {
          "系統語言": team_sys_language,
          "系統類別": team_sys_category,
          "快捷頁籤名稱": team_tag_name,
          "標題名稱": team_title,
          "次標題": team_subtitle,
          "姓名": team_name,
          "備註": team_memo
        }
        appendSpreadsheet_team(team_newRow);
        alert('Team content updated!');
        break;

      case 'Services':
        const doc_ser = new GoogleSpreadsheet('10RbLqqMM_hU9wsB4ROexwjlbxytBj_bgqsJ7ZlpgGYY');   
        appendSpreadsheet_ser = async (row) => {
          try {
            await doc_ser.useServiceAccountAuth(require('./CONST/tzubinlin-web-329402-bd489671740f.json'));  
            await doc_ser.loadInfo();
            const sheet_ser = doc_ser.sheetsByTitle['社會服務'];
            const result = await sheet_ser.addRow(row);
          } catch (e) {
            console.error('Error: ', e);
          }
        };
        let ser_tmp = this.state.input_section.split(' ');
        let ser_sys_language = ser_tmp.shift();
        let ser_sys_category = ser_tmp.pop();
        let ser_title = ser_tmp.join(' ');
        let ser_tag_name = '';
        if (ser_sys_language === '英文') {
          //console.log(this.state.proj_DB[0], proj_sys_category);
          if (this.state.ser_DB[0][ser_sys_category][0] !== undefined) {
            if (this.state.ser_DB[0][ser_sys_category][0]['快捷頁籤名稱'] !== undefined) {
              ser_tag_name = this.state.ser_DB[0][ser_sys_category][0]['快捷頁籤名稱'];
            }  
          }
        }
        else if (ser_sys_language === '中文') {
          if (this.state.ser_DB[1][ser_sys_category][0] !== undefined) {
            if (this.state.ser_DB[1][ser_sys_category][0]['快捷頁籤名稱'] !== undefined) {
              ser_tag_name = this.state.ser_DB[1][ser_sys_category][0]['快捷頁籤名稱'];
            } 
          }
        }
        let ser_city = document.getElementById('ser_input_city').value;
        let ser_subject = document.getElementById('ser_input_subject').value;
        let ser_memo = document.getElementById('ser_input_memo').value;
       
        const ser_newRow = {
          "系統語言": ser_sys_language,
          "系統類別": ser_sys_category,
          "快捷頁籤名稱": ser_tag_name,
          "標題名稱": ser_title,
          "城市": ser_city,
          "主題": ser_subject,
          "備註": ser_memo
        }
        appendSpreadsheet_ser(ser_newRow);
        alert('Services content updated!');
        break;

      default:
        break;
    }

    //let next_page = this.state.input_page;
    this.setState({
      input_page: '',
      input_section: '',
      input_preview: '',
      show_input_check_text: false,
      nowPage: 'Profile'
    })
    
  }

  authentication = () => {

    //console.log('aaaa');
    let flag = false;
    let user_id = document.getElementById('input_user_id').value;
    let user_pw = document.getElementById('input_user_pw').value;
    let admin_id_pw = this.state.admin_id_pw;
    for (let i = 0 ; i < admin_id_pw.length ; i ++) {
      if (admin_id_pw[i][0].trim() === user_id.trim() && admin_id_pw[i][1].trim() === user_pw.trim()) {
        flag = true;
        break;
      }
    }
    //console.log(admin_id_pw, user_id, user_pw);
    //console.log(flag);

    if (flag === true) {
      alert('認證成功！');
      this.setState({
        admin_authenticated: flag
      });
    }
    else {
      alert('認證失敗，如仍欲登入，請洽管理人員');
      this.setState({
        nowPage: 'Profile'
      })
    }
    
  }



  render() {
    return (


      <div className = "App">
     

        <HEADER 
          changePage = {this.changePage}
          to_input_page = {this.to_input_page}
          nowLanguage = {this.state.nowLanguage}
          nowPage = {this.state.nowPage}
          introENScrollTop = {this.state.introENScrollTop}
          introENScrollTopThres = {this.state.introENScrollTopThres}
          introCHScrollTop = {this.state.introCHScrollTop}
          introCHScrollTopThres = {this.state.introCHScrollTopThres}

          publiENScrollTop = {this.state.publiENScrollTop}
          publiENScrollTopThres = {this.state.publiENScrollTopThres}
          publiCHScrollTop = {this.state.publiCHScrollTop}
          publiCHScrollTopThres = {this.state.publiCHScrollTopThres}


          prjENScrollTop = {this.state.prjENScrollTop}
          prjENScrollTopThres = {this.state.prjENScrollTopThres}
          prjCHScrollTop = {this.state.prjCHScrollTop}
          prjCHScrollTopThres = {this.state.prjCHScrollTopThres}

          teamENScrollTop = {this.state.teamENScrollTop}
          teamENScrollTopThres = {this.state.teamENScrollTopThres}
          teamCHScrollTop = {this.state.teamCHScrollTop}
          teamCHScrollTopThres = {this.state.teamCHScrollTopThres}

          serENScrollTop = {this.state.serENScrollTop}
          serENScrollTopThres = {this.state.serENScrollTopThres}
          serCHScrollTop = {this.state.serCHScrollTop}
          serCHScrollTopThres = {this.state.serCHScrollTopThres}

          data_finish_loading = {this.state.data_finish_loading}
        />


        <MAIN_CONTENT 
          nowPage = {this.state.nowPage}
          nowLanguage = {this.state.nowLanguage}
        
          scrollIntroEN = {this.scrollIntroEN}
          scrollIntroCH = {this.scrollIntroCH}

          scrollPubliEN = {this.scrollPubliEN}
          scrollPubliCH = {this.scrollPubliCH}

          scrollPrjEN = {this.scrollPrjEN}
          scrollPrjCH = {this.scrollPrjCH}

          scrollTemEN = {this.scrollTemEN}
          scrollTemCH = {this.scrollTemCH}

          scrollSerEN = {this.scrollSerEN}
          scrollSerCH = {this.scrollSerCH}

          profil_DB = {this.state.profil_DB}
          publi_DB = {this.state.publi_DB}
          proj_DB = {this.state.proj_DB}
          team_DB = {this.state.team_DB}
          ser_DB = {this.state.ser_DB}

          input_page = {this.state.input_page}
          input_section = {this.state.input_section}
          show_input_check_text = {this.state.show_input_check_text}
          input_preview = {this.state.input_preview}

          input_page_selected = {this.input_page_selected}
          input_section_selected = {this.input_section_selected}
          input_check_btn_handler = {this.input_check_btn_handler}
          input_submit = {this.input_submit}

          data_finish_loading = {this.state.data_finish_loading}

          publiENProfilPicUp = {this.state.publiENProfilPicUp}
          publiCHProfilPicUp = {this.state.publiCHProfilPicUp}
          publiENScrollTop = {this.state.publiENScrollTop}
          publiENScrollTopThres = {this.state.publiENScrollTopThres}
          publiCHScrollTop = {this.state.publiCHScrollTop}
          publiCHScrollTopThres = {this.state.publiCHScrollTopThres}

          authentication = {this.authentication}
          admin_authenticated = {this.state.admin_authenticated}
        />

        
        <FOOTER 
          nowLanguage = {this.state.nowLanguage}
          changeLanguage = {this.changeLanguage}
        />
      </div>

    );
  } 
}

export default App;
