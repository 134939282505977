import img_services from '../img/services-photo-0.6.png';
import img_services_new from '../img/services_new.jpg';
import './MAIN_CONTENT_SERVICES_EN.css';


const MAIN_CONTENT_SERVICES_EN = (props) => {
	

  let all_cat_id = Object.keys(props.serEN_DB);
  let all_cat_content = [];
  let publiNavbar = [];
  for (let i = 0 ; i < all_cat_id.length ; i ++) {
    let ref_id = '#' + all_cat_id[i];
    if (props.serEN_DB[all_cat_id[i]][0]['快捷頁籤名稱'] !== undefined) {
      if (props.serEN_DB[all_cat_id[i]][0]['快捷頁籤名稱'] !== '') {
        publiNavbar.push(<li><a href = {ref_id}>{props.serEN_DB[all_cat_id[i]][0]['快捷頁籤名稱']}</a></li>);
      }
    }
    

    if (props.serEN_DB !== undefined) {
      let ser_city_subject = {'*': []};
      if (props.serEN_DB[all_cat_id[i]].length > 0) {
        
        for (let j = 0 ; j < props.serEN_DB[all_cat_id[i]].length ; j ++) {
          let has_city = false;
          let target = props.serEN_DB[all_cat_id[i]][j];

          if (target['城市'] !== undefined) {
            if (target['城市'] !== '') {
              has_city = true;
              if (ser_city_subject[target['城市']] !== undefined) {
                ser_city_subject[target['城市']].push(<li>{target['主題']}</li>);
              }
              else {
                ser_city_subject[target['城市']] = [<li>{target['主題']}</li>];
              }
            }
          }

          if (!has_city) {
            ser_city_subject['*'].push(<li>{target['主題']}</li>);
          }
        }
      }
      all_cat_content.push(<h3 id = {all_cat_id[i]}>{props.serEN_DB[all_cat_id[i]][0]['標題名稱']}</h3>);
      all_cat_content.push(<ol>{ser_city_subject['*']}</ol>);
      for (let item in ser_city_subject) {
        if (item !== '*') {
          all_cat_content.push(<h4>{item}</h4>);
          all_cat_content.push(<ol>{ser_city_subject[item]}</ol>);
        }
        
      }

      all_cat_content.push(<br />);
      all_cat_content.push(<br />);
    }
  }


  let all_publi_content = all_cat_content;
  
  


	return (

		 <div class="inner inner-services" id = 'inner_services_content_en'>
        <div class="big column" id = 'serEN' onScroll = {props.scrollSerEN}>
          <div class="menu-bar">
            <ul>

            {publiNavbar}
              
            </ul>
          </div>
          <div class="space"></div>
          {all_publi_content}

        

          <br/>
          <br/>
          <br/>

        </div>
        <div class="small column">
          <div class="photos">
            <img class="scroll-up" src={img_services_new} />
          </div>
        </div>
      </div>
	);

}

export default MAIN_CONTENT_SERVICES_EN;






{/*

<li><a href="#ministry_of_education">MOE</a></li>
<li><a href="#naer">NAER</a></li>
<li><a href="#local-gov">Local Authorities</a></li>
<li><a href="#ntnu">NTNU</a></li>
<!--li><a href="#school-services">School</a></li-->
<li><a href="#spread">Academic Services</a></li>


*/}